import React, { FC } from 'react'
import translate from '../../translate'

interface Props {
  text: string
  setText: (a: string) => void
  onClick: (a: string) => void
  isActive: boolean
  invitation?: string
}

const NetworkingInput: FC<Props> = ({ text, setText, onClick, isActive, invitation }) => {
  return (
    <div className="networking-input">
      {/* <Icon name={Icons.attachment} color={IconsColor.black} hoverColor={IconsColor.black} /> */}
      <div className="networking-input__container">
        <input
          className="networking-input__input"
          placeholder={translate('write_a_message')}
          maxLength={255}
          type="text"
          value={text}
          disabled={invitation ? !invitation : !isActive}
          onChange={(e) => setText(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onClick(text)
              setText('')
            }
          }}
        />
        {/* <Icon
          className="networking-input__icon"
          name={Icons.smile}
          color={IconsColor.xiaomi}
          hoverColor={IconsColor.black}
        /> */}
      </div>
      {invitation === 'REJECT' && (
        <button
          onClick={() => {
            onClick(text)
            setText('')
          }}
        >
          {translate('inv_reject')}
        </button>
      )}
      {invitation === 'ACCEPT' && (
        <button
          onClick={() => {
            onClick(text)
            setText('')
          }}
        >
          {translate('send_invitation')}
        </button>
      )}
      {!invitation && (
        <button
          disabled={!isActive}
          onClick={() => {
            onClick(text)
            setText('')
          }}
        >
          {translate('send')}
        </button>
      )}
    </div>
  )
}

export default NetworkingInput
