import React from 'react'
import Dashboard from '../../views/Dashboard'
import logo from '../../../images/logo-wide-meetinga.svg'
import translate from '../../translate'

const Welcome: React.FC = () => {
  return (
    <Dashboard activePage="welcome">
      <div className="main__container">
        <div className="main__wrapper">
          <div className="welcome__wrapper">
            <img src={logo} alt="Meetinga" />
            <p className="welcome__p">{translate('welcome')}</p>
          </div>
        </div>
      </div>
    </Dashboard>
  )
}

export default Welcome
