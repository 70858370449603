import React, { FC } from 'react'
import './styles.scss'

type GamificationTitleProps = {
  title: string
}

export const GamificationTitle: FC<GamificationTitleProps> = ({ title }) => {
  return <h1 className="gamification-title">{title}</h1>
}
