import React, { FC } from 'react'
import { CurrentExhibitorProps } from '..'
import '../styles.scss'
import { GamificationTitle } from '../GamificationTitle'
import ExhibitorDefaultLogo from '../../../../images/building.svg'
import { EVENT_URL } from '../../../services/consts'
import { fetcherDashboard } from '../../../services/api'
import useSWR from 'swr'

type GamificationExhibitorListProps = {
  exhibitors: {
    id: number
    name: string
  }[]
  currentExhibitor: CurrentExhibitorProps
  setCurrentExhibitor: (exhibitor: CurrentExhibitorProps) => void
  token: string
  currentSlug?: string
}

export const GamificationExhibitorList: FC<GamificationExhibitorListProps> = ({
  exhibitors,
  currentExhibitor,
  setCurrentExhibitor,
  token,
  currentSlug,
}) => {
  const { data: dataEvent } = useSWR<any>(
    `${EVENT_URL}/${currentSlug}/pl/api/events/content`,
    (url: string) => fetcherDashboard(url, token, { Authorization: `JWT ${token}` }),
  )

  return (
    <div className="gamification-wrapper">
      <GamificationTitle title="Zarządanie grywalizacją" />

      <span className="gamification-send-code-text">
        Kliknij na kafelek wystawcy żeby ustawić kod oraz punktację!
      </span>

      {currentSlug && (
        <span className="gamification-send-code-subtext">Aktualne wydarzenie: {currentSlug}</span>
      )}

      <div className="gamification-exhibitors-wrapper">
        {exhibitors.map((element, index) => {
          return (
            <button
              key={index}
              className="gamification-exhibitor-tile"
              onClick={() => setCurrentExhibitor({ id: element.id, name: element.name })}
            >
              <div
                className={`${
                  currentExhibitor.id === element.id
                    ? 'gamification-exhibitor-tile-image-wrapper gamification-exhibitor-tile-image-wrapper--active'
                    : 'gamification-exhibitor-tile-image-wrapper'
                }`}
              >
                <img
                  alt="logo"
                  src={
                    dataEvent && dataEvent.map_points && dataEvent.map_points.length > 0
                      ? dataEvent.map_points.find((el: any) => el.id === element.id)
                        ? dataEvent.map_points.find((el: any) => el.id === element.id).logo
                        : ExhibitorDefaultLogo
                      : ExhibitorDefaultLogo
                  }
                  className="gamification-exhibitor-tile-image"
                />
              </div>
              <div
                className={`${
                  currentExhibitor.id === element.id
                    ? 'gamification-exhibitor-is-voted gamification-exhibitor-is-voted--active'
                    : 'gamification-exhibitor-is-voted'
                }`}
              >
                <span>{element.name === 'Orion media' ? 'Orion Media Group' : element.name}</span>
              </div>
            </button>
          )
        })}
      </div>
    </div>
  )
}
