import React from 'react'
import { NavLink } from 'react-router-dom'
import '../styles.scss'
import { selectLanguage } from '../../../redux/dashboard/selectors'
import { useSelector } from 'react-redux'

export const GamificationLink = () => {
  const lang = useSelector(selectLanguage)
  return (
    <NavLink to={'/gamification'} className="gamification-link">
      {lang === 'pl' ? 'Grywalizacja' : 'Gamification'}
    </NavLink>
  )
}
