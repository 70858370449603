import { RootState } from '../store'

export const selectUser = (state: RootState) => state.user.data
export const selectUserToken = (state: RootState) => state.user.token
export const selectUserLoading = (state: RootState) => state.user.loading
export const selectUserError = (state: RootState) => state.user.error
export const selectEventsFavorites = (state: RootState) => state.user.eventsFavorites
export const selectNotes = (state: RootState) => state.user.notes
export const selectChangeRoute = (state: RootState) => state.user.changeRoute
export const selectUserFavorites = (state: RootState) => state.user.userFavorites
