import React, { FC, useMemo } from 'react'
import Icon from '../Icon'
import { Icons, IconsColor, IconsFillType } from '../../utils/enums'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectSlug } from '../../redux/dashboard/selectors'
interface Props {
  img?: string
  title: string
  backgroundColor?: string
  iconName?: Icons
  iconColor?: IconsColor
  iconColorHover?: IconsColor
  iconType?: IconsFillType
  isSelected?: boolean | null
  mapIcon?: boolean
  id?: number
  onClick?: (a: number | null) => void
}

const MainAsideItem: FC<Props> = ({
  img,
  title,
  backgroundColor = 'grey',
  iconName,
  iconColor,
  iconColorHover,
  iconType,
  isSelected,
  mapIcon,
  id,
  onClick,
}) => {
  const hoverColor = useMemo(() => {
    if (iconColorHover) {
      return IconsColor[iconColorHover]
    } else if (iconColor) {
      return IconsColor[iconColor]
    } else {
      return IconsColor.white
    }
  }, [iconColorHover, iconColor])

  const slug = useSelector(selectSlug)

  return (
    <div className={`main-aside-item ${isSelected ? 'main-aside-item--active' : ''}`}>
      <div className="main-aside-item__text-wrapper">
        <Link className="main-aside-item__link" to={`/event/${slug}/exhibitors/${id}`}>
          <div className={`main-aside-item__image main-aside-item__image--${backgroundColor}`}>
            {iconName ? (
              <Icon
                name={Icons[iconName]}
                color={iconColor ? IconsColor[iconColor] : IconsColor.white}
                hoverColor={hoverColor}
                type={iconType ? IconsFillType[iconType] : IconsFillType.fill}
              />
            ) : (
              <img src={img} alt={title} />
            )}
          </div>

          <span>{title}</span>
        </Link>
      </div>

      {mapIcon && (
        <div
          onClick={() => id && onClick?.(id)}
          className={`main-aside-item__image main-aside-item__image--${backgroundColor}`}
        >
          <Icon name={Icons.mark} color={IconsColor.black} hoverColor={IconsColor.xiaomi} />
        </div>
      )}
    </div>
  )
}

export default MainAsideItem
