import React, { FC, useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Tab, Tabs } from '../Tabs'
import { isAfter, isBefore, differenceInMinutes } from 'date-fns'
import DownloadFile from '../DownloadFile'
import TabButton from '../TabButton'
import Localization from '../Localization'
import TabTitle from '../TabTitle'
import Person from '../Person'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../Icon'
import dashboardMessage from '../../components/Message'
import { DashboardMessageTypes } from '../../utils/enums'
import { getURLName, getDayName, getTimeFromDate, formatDate } from '../../utils/functions'

import PollModal from '../PollModal'
import QuestionModal from '../QuestionModal'

import { useSelector, useDispatch } from 'react-redux'
import {
  selectAgendaItemsPeople,
  selectExhibitor,
  selectEventConfiguration,
} from '../../redux/dashboard/selectors'
import { asyncFetchAgendaItem } from '../../redux/dashboard/async'
import { Attachments, AgendaItemsProps, PeopleProps } from '../../redux/dashboard/interfaces'
import translate from '../../translate'
import ReactHlsPlayer from 'react-hls-player'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import InfoBarChat from '../InfoBarChat'
import { useDashboard } from '../../hooks/useDashboard'

const InfoBar: FC<{ data: AgendaItemsProps }> = ({ data }) => {
  const [now, setNow] = useState(Date.now())
  const playerRef = React.useRef(null)

  const actualDay = new Date(data.datetime_start).getDay()

  const people = useSelector(selectAgendaItemsPeople(data.people_ids))
  const localization = useSelector(selectExhibitor(data.map_point_id))

  const { slug, language: lang } = useDashboard()

  const dispatch = useDispatch()

  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const event = useSelector(selectEventConfiguration)

  useEffect(() => {
    if (slug !== undefined && data) {
      dispatch(
        asyncFetchAgendaItem({
          slug,
          id: data.id,
          additionalHeaders: token ? { Authorization: `JWT ${token}` } : {},
        }),
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.id])

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now())
    }, 10000)

    return () => {
      clearTimeout(interval)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const startDate = useMemo(() => {
    return new Date(data.datetime_start)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const startDateToShow = useMemo(() => {
    return new Date(data.datetime_start)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const endDate = useMemo(() => {
    return new Date(data.datetime_end)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const isActive = useMemo(() => {
    return isAfter(now, startDateToShow) && isBefore(now, endDate)
  }, [startDateToShow, endDate, now])

  const length = useMemo(() => {
    return differenceInMinutes(endDate, startDate)
  }, [startDate, endDate])

  const currentMinute = useMemo(() => {
    return differenceInMinutes(now, startDate)
  }, [startDate, now])

  const NO_STREAM = useMemo(() => {
    return !event?.streaming_enabled
    // return user?.user_groups1?.some((element) => element.name === 'Stacjonarni')
  }, [event?.streaming_enabled])

  const NO_POLLS = useMemo(() => {
    if (data.poll_id === undefined) {
      return true
    }

    // return user?.user_groups1?.some((element) => element.name === 'Prelegenci')
  }, [data])

  const NO_AUDIENCE = useMemo(() => {
    if (data.audience_questions_enabled === false) {
      return true
    }
  }, [data])

  useEffect(() => {
    const interval = setInterval(() => {
      const diff = differenceInMinutes(new Date(data.datetime_end), new Date())
      if (diff <= 5 && diff >= 0) {
        dashboardMessage(
          DashboardMessageTypes.success,
          'Nie zapomnij kliknąć w przycisk `ankieta` żeby ocenić prelekcję! ',
          0,
        )
        clearInterval(interval)
      } else if (diff < 0) {
        clearInterval(interval)
      }
    }, 30000)
    return () => clearInterval(interval)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <aside className="information">
      <Tabs>
        <Tab title={translate('information')}>
          <>
            <div className="information__head">
              <Icon
                name={Icons.board}
                color={IconsColor.xiaomi}
                hoverColor={IconsColor.xiaomi}
                type={IconsFillType.stroke}
              />
              <div>
                <span>
                  {getTimeFromDate(data.datetime_start)} - {getTimeFromDate(data.datetime_end)}
                </span>
                {/* <Icon
                  name={Icons.bell}
                  color={IconsColor.grey}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.stroke}
                />
                <Icon
                  name={Icons.calendar}
                  color={IconsColor.grey}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.stroke}
                /> */}
              </div>
            </div>
            <h3>{data.name}</h3>
            <time className="information__time">
              {getDayName(actualDay, lang)} {formatDate(data.datetime_start)}
            </time>

            {data?.stream_url && isActive && currentMinute && length && !NO_STREAM && (
              <>
                <h4>{translate('streaming')}</h4>
                {/* <a href={data?.stream_url} target="_blank" rel="noreferrer" className="call-widget">
                  <img src="images/camera.svg" alt="camera " />
                  <label>
                    <div>
                      {isLive ? <span>{translate('live')}</span> : <div></div>}
                      <time>
                        {currentMinute > 0
                          ? `${Math.floor(currentMinute / 60)}h : ${Number(
                              currentMinute % 60,
                            ).toFixed(0)}m`
                          : '00:00'}
                      </time>
                    </div>

                    <progress value={currentMinute} max={length}></progress>
                  </label>
                </a> */}
                <ReactHlsPlayer
                  src={data.stream_url}
                  playerRef={playerRef}
                  autoPlay={false}
                  controls={true}
                  width="100%"
                  height="auto"
                />
                {user?.networking_on ? (
                  <InfoBarChat id={data.id} />
                ) : (
                  'Żeby pisać na czacie musisz mieć włączony networking. Możesz to zrobić w ustawieniach profilu.'
                )}
              </>
            )}

            <p>{data.short_content}</p>

            {data.content && <div dangerouslySetInnerHTML={{ __html: data.content }}></div>}

            {data.attachments && data.attachments?.length > 0 && (
              <TabTitle title={translate('attachments')} />
            )}

            {data.attachments?.map((element: Attachments) => {
              return (
                <div key={element.id} className="information__section information__section--file">
                  <DownloadFile filename={getURLName(element.url)} link={element.url} />
                </div>
              )
            })}

            {localization && <TabTitle title={translate('localization')} />}

            {localization && (
              <div className="information__section">
                <Link to={`/event/${slug}/map`}>
                  <Localization
                    name={localization?.name}
                    address={
                      localization.stand_number &&
                      `${translate('stand')} ${localization.stand_number}`
                    }
                  />
                </Link>
              </div>
            )}

            {people!.length > 0 && <TabTitle title={translate('people')} />}

            {people?.map((element: PeopleProps) => {
              return (
                <div key={element.id} className="information__section information__section--person">
                  <Person
                    type={'NONE'}
                    img={element.photo_url}
                    name={`${element.firstname} ${element.lastname}`}
                    title={element.post}
                  />
                </div>
              )
            })}

            <div className="information__section--buttons">
              <TabButton
                iconName={Icons.share}
                iconColor={IconsColor.white}
                iconColorHover={IconsColor.xiaomi}
                onClick={() => {
                  navigator.clipboard.writeText(window.location.href)
                  dashboardMessage(DashboardMessageTypes.success, translate('copied_URL'))
                }}
              />
              {!NO_POLLS && <PollModal id={data.id} />}
              {!NO_AUDIENCE && <QuestionModal id={data.id} isAgendaItem={false} />}
            </div>
          </>
        </Tab>

        <Tab title="Chat">
          <div></div>
        </Tab>
      </Tabs>
    </aside>
  )
}

export default InfoBar
