import { Events } from '../redux/dashboard/interfaces'

export const getEventLanguage = (events: Events[] | null, slug: string, lang: string) => {
  if (events) {
    const languages = events.find((element) => element.slug === slug)?.languages.split(',') || [
      'en',
    ]

    if (languages?.includes(lang)) {
      return lang
    }

    return languages[0]
  }

  return 'en'
}
