import React, { FC, useMemo } from 'react'

interface Props {
  img: string
  name?: string
  isOnline?: boolean
}

const PersonImage: FC<Props> = ({ img, name, isOnline }) => {
  const onlineStatus = useMemo(() => {
    if (isOnline) {
      return 'person-image--online'
    } else if (isOnline === false) {
      return 'person-image--offline'
    } else {
      return ''
    }
  }, [isOnline])

  return (
    <div className={`person-image ${onlineStatus}`}>
      <img src={img} alt={name || 'Unknown user'} />
    </div>
  )
}

export default PersonImage
