import React, { FC } from 'react'
import '../styles.scss'
import { fetcher } from '../../../services/api'
import { GAMIFICATION_URL } from '../../../../config'
import useSWR from 'swr'
import { GamificationSlug } from '../GamificationSlug'
import { GamificationExhibitorList } from '../GamificationExhibitorList'
import { GamificationExhibitor } from '../GamificationExhibitor'
import { GamificationReset } from '../GamificationReset'
import { CurrentExhibitorProps, GamificationExhibitorsProps } from '..'
import { GamificationCSV } from '../GamificationCSV'

type GamificationAdminProps = {
  token: string
  currentExhibitor: CurrentExhibitorProps
  setCurrentExhibitor: (exhibitor: CurrentExhibitorProps) => void
}

export type GamificationFetchSlugProps = {
  result: number
  payload: string
  statusCode?: number
}

export const GamificationAdmin: FC<GamificationAdminProps> = ({
  token,
  currentExhibitor,
  setCurrentExhibitor,
}) => {
  const { data, error, mutate } = useSWR<GamificationExhibitorsProps>(
    `${GAMIFICATION_URL}/exhibitor/list`,
    (url: string) => fetcher(url, token),
  )

  const { data: dataSlug, mutate: mutateSlug } = useSWR<GamificationFetchSlugProps>(
    `${GAMIFICATION_URL}/exhibitor/current-event`,
    (url: string) => fetcher(url, token),
  )

  if (error) {
    return <div className="gamification-wrapper">Coś poszło nie tak z wczytywaniem ustawień</div>
  }

  if (data?.statusCode === 403) {
    return (
      <>
        {dataSlug && dataSlug.payload === null ? (
          <GamificationSlug token={token} fetchGamification={mutate} />
        ) : (
          <>
            <div className="gamification-wrapper">
              <span className="gamification-send-code-text">
                Odmowa dostępu. <br />
                Czy na pewno jesteś administratorem aktualnego wydarzenia {dataSlug?.payload}?
              </span>
            </div>
          </>
        )}
      </>
    )
  }

  return (
    <div>
      {data && data.payload && (
        <>
          {data.payload.length > 0 ? (
            <>
              <GamificationExhibitorList
                exhibitors={data.payload}
                currentExhibitor={currentExhibitor}
                setCurrentExhibitor={setCurrentExhibitor}
                token={token}
                currentSlug={dataSlug?.payload}
              />

              {currentExhibitor.id !== 0 && (
                <GamificationExhibitor
                  id={currentExhibitor.id}
                  token={token}
                  currentExhibitor={currentExhibitor}
                />
              )}
            </>
          ) : (
            <div style={{ marginBottom: '30px' }}>Brak wystawców</div>
          )}

          <GamificationCSV token={token} />

          <GamificationReset
            token={token}
            fetchGamification={mutate}
            fetchSlug={mutateSlug}
            setCurrentExhibitor={setCurrentExhibitor}
          />
        </>
      )}
    </div>
  )
}
