import React, { FC } from 'react'
import { Link } from 'react-router-dom'
import { IconsColor, Icons, IconsFillType, Languages } from '../../utils/enums'
import Icon from '../Icon'
import { Menu, Dropdown } from 'antd'
import { useSelector, useDispatch } from 'react-redux'
import { selectUser } from '../../redux/user/selectors'
import { selectEventConfiguration, selectLanguage } from '../../redux/dashboard/selectors'
import { logout } from '../../redux/user/slice'
import { changeLang } from '../../redux/dashboard/slice'

import userImage from '../../../images/user.png'
import translate, { changeLanguage } from '../../translate'

interface Props {
  className?: string
}

const Header: FC<Props> = ({ className }) => {
  const dispatch = useDispatch()
  const event = useSelector(selectEventConfiguration)
  const user = useSelector(selectUser)
  const lang = useSelector(selectLanguage)

  const userMenu = (
    <Menu>
      <Menu.Item key="0">
        <Link to={'/profil'}>
          <div className="header-menu-item">
            <Icon
              name={Icons.settingsProfile}
              color={IconsColor.grey}
              hoverColor={IconsColor.xiaomi}
              type={IconsFillType.stroke}
            />
            {translate('settings')}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="1">
        <Link to={'/notes'}>
          <div className="header-menu-item">
            <Icon name={Icons.scheduler} color={IconsColor.grey} hoverColor={IconsColor.xiaomi} />
            {translate('notes')}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        <Link to={'/materialy'}>
          <div className="header-menu-item">
            {<Icon name={Icons.folder} color={IconsColor.grey} hoverColor={IconsColor.xiaomi} />}
            {translate('materials')}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="3">
        <Link to={'/ulubione'}>
          <div className="header-menu-item">
            <Icon name={Icons.heartFull} color={IconsColor.grey} hoverColor={IconsColor.xiaomi} />
            {translate('favorites')}
          </div>
        </Link>
      </Menu.Item>
      <Menu.Item key="4" onClick={() => dispatch(logout())}>
        <Link to={'/logowanie'}>
          <div className="header-menu-item">
            <Icon name={Icons.exit} color={IconsColor.tesla} hoverColor={IconsColor.grey} />
            {translate('logout')}
          </div>
        </Link>
      </Menu.Item>
    </Menu>
  )

  return (
    <header className={className ? className : ''}>
      <div className="header-logo">
        {event?.icon_url && <img src={event.icon_url} alt="logo" />}
        <span>{event?.name}</span>
      </div>
      <div className="header-profile">
        {user && (
          <div className="header-profile__user">
            <Dropdown overlay={userMenu} trigger={['click']}>
              <button>
                <Icon
                  name={Icons.downArrow}
                  color={IconsColor.xiaomi}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
                <span>{`${user?.firstname} ${user?.lastname}`}</span>
                <img
                  className="header-profile__image"
                  src={user?.photo_url || userImage}
                  alt="user"
                />
              </button>
            </Dropdown>
          </div>
        )}

        {/* <button className="header-profile__notifications">
          <Icon
            name={Icons.bell}
            color={IconsColor.grey}
            hoverColor={IconsColor.xiaomi}
            type={IconsFillType.stroke}
          />
          <span>3</span>
        </button> */}
        <button
          className="header-language-button"
          onClick={() => {
            dispatch(changeLang(Languages.pl))
            changeLanguage(Languages.pl)
            window.location.reload()
          }}
          disabled={lang === Languages.pl}
        >
          <span aria-label="pl-flag" role="img">
            🇵🇱
          </span>
        </button>
        <button
          className="header-language-button"
          onClick={() => {
            dispatch(changeLang(Languages.en))
            changeLanguage(Languages.en)
            window.location.reload()
          }}
          disabled={lang === Languages.en}
        >
          <span aria-label="usa-flag" role="img">
            🇺🇸
          </span>
        </button>
      </div>
    </header>
  )
}

export default Header
