import React, { useState, FC, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { Link } from 'react-router-dom'

import Login from '../../views/Login'
import LoginInput from '../../components/LoginInput'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import { useDispatch, useSelector } from 'react-redux'
import { asyncFetchPasswordReset } from '../../redux/user/async'
import { Button } from 'antd'
import translate from '../../translate'

const PasswordRemindPage: FC = () => {
  const history = useHistory()
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)

  const dispatch = useDispatch()

  const [email, setEmail] = useState({ email: '' })

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const body = email
    dispatch(asyncFetchPasswordReset({ additionalHeaders: {}, body }))
  }

  useEffect(() => {
    if (user !== null && token !== null) {
      history.goBack()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, token])

  return (
    <Login>
      <div className="login-page">
        <h1>{translate('remind_password')}</h1>
        <p>{translate('remind_password_text')}</p>

        <form onSubmit={handleSubmit}>
          <LoginInput
            label={translate('email')}
            name="email"
            placeholder={translate('email')}
            type="email"
            onChange={setEmail}
          />
          <Button type="primary" htmlType="submit">
            {translate('send')}
          </Button>
        </form>

        <div className="login-page__links">
          <span>
            {translate('no_account')}{' '}
            <Link className="login-page__link" to="/rejestracja">
              {translate('register')}
            </Link>
          </span>
          <span>
            {translate('have_account')}{' '}
            <Link className="login-page__link" to="/logowanie">
              {translate('sign_in')}
            </Link>
          </span>
        </div>
      </div>
    </Login>
  )
}

export default PasswordRemindPage
