import React from 'react'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'
import { HashRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import App from './js/views/App'

import { store, history } from './js/redux/store'
import * as serviceWorker from './js/serviceWorker'

import './scss/app.scss'
import 'antd/dist/antd.css'

import { CssBaseline } from '@material-ui/core'

import { IntlProvider } from 'react-intl'
import { messages } from './js/translate'
import { useSelector } from 'react-redux'
import { selectLanguage } from './js/redux/dashboard/selectors'

const persistor = persistStore(store)

const VideoApp = () => {
  const lang = useSelector(selectLanguage)

  return (
    <IntlProvider locale={lang} messages={messages[lang]}>
      <App />
    </IntlProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)

root.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <CssBaseline />
      <ConnectedRouter history={history}>
        <HashRouter>
          <VideoApp />
        </HashRouter>
      </ConnectedRouter>
    </PersistGate>
  </Provider>,
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
