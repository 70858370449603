import React, { FC } from 'react'
import '../styles.scss'
import { GamificationResetModal } from './GamificationResetModal'
import { KeyedMutator } from 'swr'
import { CurrentExhibitorProps, GamificationExhibitorsProps } from '..'
import { GamificationFetchSlugProps } from '../GamificationAdmin'

type GamificationSlugProps = {
  token: string
  fetchGamification: KeyedMutator<GamificationExhibitorsProps>
  fetchSlug: KeyedMutator<GamificationFetchSlugProps>
  setCurrentExhibitor: (exhibitor: CurrentExhibitorProps) => void
}

export const GamificationReset: FC<GamificationSlugProps> = ({
  token,
  fetchGamification,
  fetchSlug,
  setCurrentExhibitor,
}) => {
  return (
    <div className="gamification-wrapper">
      <span className="gamification-send-code-subtext">
        Zresetuj grę (jeśli nie zapisałeś wcześniej wyników zostaną one utracone!):
      </span>
      <GamificationResetModal
        token={token}
        fetchGamification={fetchGamification}
        fetchSlug={fetchSlug}
        setCurrentExhibitor={setCurrentExhibitor}
      />
    </div>
  )
}
