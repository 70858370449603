import { RootState } from '../store'
import {
  AgendaItemsProps,
  ArticlesProps,
  GalleriesProps,
  MapPointsProps,
  PeopleProps,
} from './interfaces'

export const selectMenuItems = (state: RootState) => state.dashboard.data?.menu_items

export const selectMapAreas = (state: RootState) => state.dashboard.data?.map_areas

export const selectMapPoints = (state: RootState) => state.dashboard.data?.map_points

export const selectAllDashboardItems = (state: RootState) => state.dashboard.data

export const selectArticles = (state: RootState) => state.dashboard.data?.articles

export const selectAddresses = (state: RootState) => state.dashboard.data?.addresses

export const selectPeople = (state: RootState) => state.dashboard.data?.people

export const selectArticle = (id: string) => {
  return (state: RootState) => {
    return state.dashboard.data?.articles.find(
      (element: ArticlesProps) => element.id === Number(id),
    )
  }
}

export const selectAgendaItem = (id: number) => {
  return (state: RootState) => {
    return state.dashboard.data?.agenda_items.find((element: AgendaItemsProps) => element.id === id)
  }
}

export const selectAgendaItems = (state: RootState) => {
  const items = state.dashboard.data?.agenda_items.slice()
  return items?.sort((a: AgendaItemsProps, b: AgendaItemsProps) => {
    const aDate = new Date(a.datetime_start).getTime()
    const bDate = new Date(b.datetime_start).getTime()
    return aDate - bDate
  })
}

export const selectAgendaItemsPeople = (ids: number[]) => {
  return (state: RootState) => {
    const items = state.dashboard.data?.people.slice()
    return items?.filter((element: PeopleProps) => ids.includes(element.id))
  }
}

export const selectExhibitor = (id: string | number | undefined) => {
  return (state: RootState) => {
    const items = state.dashboard.data?.map_points.slice()
    return items?.find((element: MapPointsProps) => element.id === Number(id))
  }
}

export const selectExhibitors = (state: RootState) => {
  const items = state.dashboard.data?.map_points.slice()
  return items
    ?.sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .filter((element: MapPointsProps) => element.is_exhibitor === true)
}

export const selectGalleries = (state: RootState) => state.dashboard.data?.galleries

export const selectGalleryItem = (id: string) => {
  return (state: RootState) => {
    const items = state.dashboard.data?.galleries.slice()
    return items?.find((element: GalleriesProps) => element.id === Number(id))
  }
}

export const selectPeopleItem = (id: string) => {
  return (state: RootState) => {
    return state.dashboard.data?.people.find((element: PeopleProps) => element.id === Number(id))
  }
}

export const selectSlug = (state: RootState) => state.dashboard.data?.configuration.slug

export const selectEvents = (state: RootState) => state.dashboard.events

export const selectEventConfiguration = (state: RootState) => state.dashboard.data?.configuration

export const selectPeopleNetworking = (state: RootState) => state.dashboard.peopleNetworking

export const selectWidth = (state: RootState) => state.dashboard.width

export const selectAudienceQuestions = (state: RootState) => state.dashboard.audienceQuestions

export const selectLanguage = (state: RootState) => state.dashboard.language
