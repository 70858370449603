import React, { FC } from 'react'
import '../styles.scss'
import { GAMIFICATION_URL } from '../../../../config'
import { getFullDate } from '../GamificationRanking/helpers'

type GamificationSendCodeProps = {
  token: string
}

export const GamificationCSV: FC<GamificationSendCodeProps> = ({ token }) => {
  const downloadFile = async (options: any = {}): Promise<void> => {
    const { asName = `ranking-${getFullDate(new Date().toDateString())}` } = options
    const res = await fetch(`${GAMIFICATION_URL}/user/rank/csv`, {
      headers: { Authorization: `Bearer ${token}`, 'content-type': 'text/csv' },
    })
    if (res.status === 200) {
      const extension = 'csv'
      const blob = await res.blob()
      const link = document.createElement('a')
      link.href = URL.createObjectURL(blob)
      link.setAttribute('download', `${asName}.${extension}`)
      document.body.appendChild(link)
      link.click()
    } else {
      // eslint-disable-next-line camelcase
      const { error_code = 0 } = await res.json()
      console.log(error_code)
    }
  }

  return (
    <div className="gamification-wrapper">
      <span className="gamification-send-code-subtext">Zapisz ranking do .csv:</span>

      <button onClick={() => downloadFile()} className="gamification-button">
        Zapisz do .csv
      </button>
    </div>
  )
}
