import * as React from 'react'
import { SVGProps } from 'react'
export const GamificationEnterCodeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} {...props}>
    <g fill="none" data-name="Group 34362">
      <g data-name="Rectangle 3577">
        <rect width={18} height={16} stroke="none" rx={2} />
        <rect width={17} height={15} x={0.5} y={0.5} rx={1.5} />
      </g>
      <g data-name="Ellipse 89" transform="translate(6 4)">
        <circle cx={3} cy={3} r={3} stroke="none" />
        <circle cx={3} cy={3} r={2.5} />
      </g>
      <g data-name="Rectangle 3578">
        <path stroke="none" d="M8 11h2a4 4 0 0 1 4 4v1H4v-1a4 4 0 0 1 4-4Z" />
        <path d="M8 11.5h2a3.5 3.5 0 0 1 3.5 3.5.5.5 0 0 1-.5.5H5a.5.5 0 0 1-.5-.5A3.5 3.5 0 0 1 8 11.5Z" />
      </g>
    </g>
  </svg>
)
