import React, { FC } from 'react'
import PersonImage from '../PersonImage'
import translate from '../../translate'

interface Props {
  type:
    | 'NONE'
    | 'INVITATION_RECEIVED'
    | 'INVITATION_ACCEPTED'
    | 'INVITATION_REJECTED_BY_ME'
    | 'INVITATION_REJECTED_BY_RECEIVER'
    | 'INVITATION_SENT'
  img?: string
  name: string
  isOnline?: boolean
  title?: string
  status?: string
  className?: string
  phone?: string
  email?: string
}

const Person: FC<Props> = ({
  type,
  img,
  email,
  phone,
  name,
  isOnline,
  title,
  status,
  className,
}) => {
  return (
    <div className={`person ${className ? className : ''}`}>
      {img && <PersonImage name={name} img={img} isOnline={isOnline} />}
      <div className="person__wrapper">
        <span>
          {name} {status && <small className="person__status">{`- ${status}`}</small>}
        </span>
        {type === 'NONE' && <small>{status}</small>}
        {type === 'INVITATION_ACCEPTED' && (
          <small className="person__accepted">{translate('inv_accepted')}</small>
        )}
        {type === 'INVITATION_REJECTED_BY_ME' && (
          <small className="person__rejected">{translate('inv_rejected_by_you')}</small>
        )}
        {type === 'INVITATION_REJECTED_BY_RECEIVER' && (
          <small className="person__rejected">{translate('inv_rejected')}</small>
        )}
        {type === 'INVITATION_RECEIVED' && (
          <small className="person__sent">{translate('inv_received')}</small>
        )}

        {type === 'INVITATION_SENT' && (
          <small className="person__sent">{translate('inv_sent')}</small>
        )}
        {title && <small>{title}</small>}
        {phone && <small>{phone}</small>}
        {email && <small>{email}</small>}
      </div>
    </div>
  )
}

export default Person
