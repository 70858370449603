import React, { FC } from 'react'
import '../styles.scss'
import useSWR from 'swr'
import { fetcher, fetcherDashboard } from '../../../services/api'
import { GAMIFICATION_URL } from '../../../../config'
import { GamificationSendCode } from '../GamificationSendCode'
import { GamificationTitle } from '../GamificationTitle'
import './styles.scss'
import { GamificationFetchSlugProps } from '../GamificationAdmin'
import ExhibitorDefaultLogo from '../../../../images/building.svg'
import { EVENT_URL } from '../../../services/consts'

type GamificationExhibitorListProps = {
  userId: number
  token: string
}

export type GamificationUserExhibitorListFetchProps = {
  result: number
  payload: {
    id: number
    name: string
    voted: boolean
  }[]
  statusCode?: number
}

export const GamificationUserExhibitorList: FC<GamificationExhibitorListProps> = ({
  userId,
  token,
}) => {
  const { data, error, mutate } = useSWR<GamificationUserExhibitorListFetchProps>(
    `${GAMIFICATION_URL}/exhibitor/list/${userId}`,
    (url: string) => fetcher(url, token),
  )

  const { data: dataSlug } = useSWR<GamificationFetchSlugProps>(
    `${GAMIFICATION_URL}/exhibitor/current-event`,
    (url: string) => fetcher(url, token),
  )

  const { data: dataEvent } = useSWR<any>(
    `${EVENT_URL}/${dataSlug?.payload}/pl/api/events/content`,
    (url: string) => fetcherDashboard(url, token, { Authorization: `JWT ${token}` }),
  )

  // {{meetinga_url}}/{{event_slug}}/{{language}}/api/events/content/

  // const { data: dataSlug } = useSWR<GamificationFetchSlugProps>(
  //   `${GAMIFICATION_URL}/exhibitor/current-event`,
  //   (url) => fetcher(url, token),
  // )

  // const exhibitors = useSelector(selectExhibitors)

  if (error) {
    return <div className="gamification-wrapper">Coś poszło nie tak z wczytywaniem wystawców.</div>
  }

  if (data?.statusCode === 403) {
    return (
      <div className="gamification-wrapper">
        <GamificationTitle title="Weź udział w grywalizacji i wygraj atrakcyjne nagrody!" />

        <div className="gamification-info">
          <span className="gamification-send-code-subtext">
            1. Wejdź w zakładkę Wystawcy i sprawdź, jakie zadanie przygotował każdy Partner.
          </span>
          <br />
          <span className="gamification-send-code-subtext">
            2. Odwiedzaj stoiska i wykonuj zadania, a następnie odbieraj i wpisuj kody od wystawców
            poniżej. Każdy Partner ma swój unikalny kod na każdy dzień.
          </span>
          <br />
          <span className="gamification-send-code-subtext">
            3. Zbieraj punkty i walcz o atrakcyjne nagrody.{' '}
          </span>
          <br />
          <span className="gamification-send-code-subtext">
            4. Każdego dnia nagrodzimy 5 najlepszych uczestniczek/uczestników.{' '}
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>*</strong> Nie udało Ci się dzisiaj? Spróbuj jutro!
            Zadania będą inne.
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>**</strong> Jeśli zostałaś/eś już nagrodzony, daj
            szansę innym. Wyróżnionym można zostać maksymalnie raz.
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>***</strong> W przypadku takiej samej liczby punktów
            decydujący będzie czas realizacji zadań (od momentu wpisania pierwszego kodu do
            ostatniego).
          </span>
          <span className="gamification-send-code-subtext">
            <strong style={{ color: 'red' }}>****</strong> Zadania można realizować do 17:30.
          </span>
          <span className="gamification-send-code-text">Do wygrania m.in:</span>
          <ul className="gamification-info-list">
            <li className="gamification-send-code-subtext">
              Bilet VIP na kolejną edycję konferencji
            </li>
            <li className="gamification-send-code-subtext">
              1000, 500 i 300 zł do wykorzystania na szkolenia sprawny.marketing
            </li>
            <li className="gamification-send-code-subtext">
              eVouchery od Pluxee o wartości 500, 400, 300, 200 i 1000 zł do wykorzystania np.
              Allegro, Zalando, Xbox, Tidal, RTV Euro AGD itd.
            </li>
            <li className="gamification-send-code-subtext">
              Słuchawki Sony WF C500 od White Label Coders
            </li>
            <li className="gamification-send-code-subtext">
              Lifting profilu osobistego na LinkedInie od PR Calling
            </li>
            <li className="gamification-send-code-subtext">
              Przygotowanie wizytówki eksperckiej od PR Calling
            </li>
            <li className="gamification-send-code-subtext">
              1 godzina konsultacji z zakresu personal brandingu od PR Calling
            </li>
            <li className="gamification-send-code-subtext">Lazy bags od maxroy</li>
            <li className="gamification-send-code-subtext">
              Roczna prenumerata magazynu sprawny.marketing
            </li>
            <li className="gamification-send-code-subtext">zestaw gadżetów od Body Chief</li>
            <li className="gamification-send-code-subtext">kubki od Agencji Szpilka</li>
            <li className="gamification-send-code-subtext">PowerBox od Happy Gifts</li>
            <li className="gamification-send-code-subtext">
              Klapki Kubota i laptop case od No Fluff Jobs
            </li>
            <li className="gamification-send-code-subtext">Zestawy kosmetyków od SunewMed+</li>
            <li className="gamification-send-code-subtext">bilety do Multikina</li>
            <li className="gamification-send-code-subtext">
              roczny darmowy dostęp od Depositphotos
            </li>
            <li className="gamification-send-code-subtext">i wiele innych!</li>
          </ul>
        </div>

        <span className="gamification-send-code-text">
          Żeby wpisać kod wystawcy musisz być zarejestrowany na bieżące wydarzenie grywalizacji!{' '}
          <br />
          Czy na pewno jesteś uczestnikiem aktualnego wydarzenia {dataSlug?.payload}?
        </span>
      </div>
    )
  }

  if (data)
    return (
      <>
        <div className="gamification-wrapper">
          <GamificationTitle title="Weź udział w grywalizacji i wygraj atrakcyjne nagrody!" />

          <div className="gamification-info">
            <span className="gamification-send-code-subtext">
              1. Wejdź w zakładkę Wystawcy i sprawdź, jakie zadanie przygotował każdy Partner.
            </span>
            <br />
            <span className="gamification-send-code-subtext">
              2. Odwiedzaj stoiska i wykonuj zadania, a następnie odbieraj i wpisuj kody od
              wystawców poniżej. Każdy Partner ma swój unikalny kod na każdy dzień.
            </span>
            <br />
            <span className="gamification-send-code-subtext">
              3. Zbieraj punkty i walcz o atrakcyjne nagrody.{' '}
            </span>
            <br />
            <span className="gamification-send-code-subtext">
              4. Każdego dnia nagrodzimy 5 najlepszych uczestniczek/uczestników.{' '}
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>*</strong> Nie udało Ci się dzisiaj? Spróbuj jutro!
              Zadania będą inne.
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>**</strong> Jeśli zostałaś/eś już nagrodzony, daj
              szansę innym. Wyróżnionym można zostać maksymalnie raz.
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>***</strong> W przypadku takiej samej liczby punktów
              decydujący będzie czas realizacji zadań (od momentu wpisania pierwszego kodu do
              ostatniego).
            </span>
            <span className="gamification-send-code-subtext">
              <strong style={{ color: 'red' }}>****</strong> Zadania można realizować do 17:30.
            </span>
            <span className="gamification-send-code-text">Do wygrania m.in:</span>
            <ul className="gamification-info-list">
              <li className="gamification-send-code-subtext">
                Bilet VIP na kolejną edycję konferencji
              </li>
              <li className="gamification-send-code-subtext">
                1000, 500 i 300 zł do wykorzystania na szkolenia sprawny.marketing
              </li>
              <li className="gamification-send-code-subtext">
                eVouchery od Pluxee o wartości 500, 400, 300, 200 i 1000 zł do wykorzystania np.
                Allegro, Zalando, Xbox, Tidal, RTV Euro AGD itd.
              </li>
              <li className="gamification-send-code-subtext">
                Słuchawki Sony WF C500 od White Label Coders
              </li>
              <li className="gamification-send-code-subtext">
                Lifting profilu osobistego na LinkedInie od PR Calling
              </li>
              <li className="gamification-send-code-subtext">
                Przygotowanie wizytówki eksperckiej od PR Calling
              </li>
              <li className="gamification-send-code-subtext">
                1 godzina konsultacji z zakresu personal brandingu od PR Calling
              </li>
              <li className="gamification-send-code-subtext">Lazy bags od maxroy</li>
              <li className="gamification-send-code-subtext">
                Roczna prenumerata magazynu sprawny.marketing
              </li>
              <li className="gamification-send-code-subtext">zestaw gadżetów od Body Chief</li>
              <li className="gamification-send-code-subtext">kubki od Agencji Szpilka</li>
              <li className="gamification-send-code-subtext">PowerBox od Happy Gifts</li>
              <li className="gamification-send-code-subtext">
                Klapki Kubota i laptop case od No Fluff Jobs
              </li>
              <li className="gamification-send-code-subtext">Zestawy kosmetyków od SunewMed+</li>
              <li className="gamification-send-code-subtext">bilety do Multikina</li>
              <li className="gamification-send-code-subtext">
                roczny darmowy dostęp od Depositphotos
              </li>
              <li className="gamification-send-code-subtext">i wiele innych!</li>
            </ul>
          </div>

          <GamificationSendCode userId={userId} token={token} fetchUserExhibitorList={mutate} />

          <div className="gamification-tiles-wrapper">
            {data &&
              data.payload &&
              data.payload.map((element, index) => {
                return (
                  <div key={index} className="gamification-exhibitor-tile">
                    <div
                      className={`${
                        element.voted
                          ? 'gamification-exhibitor-tile-image-wrapper gamification-exhibitor-tile-image-wrapper--active'
                          : 'gamification-exhibitor-tile-image-wrapper'
                      }`}
                    >
                      <img
                        alt="logo"
                        src={
                          dataEvent && dataEvent.map_points && dataEvent.map_points.length > 0
                            ? dataEvent.map_points.find((el: any) => el.id === element.id)
                              ? dataEvent.map_points.find((el: any) => el.id === element.id).logo
                              : ExhibitorDefaultLogo
                            : ExhibitorDefaultLogo
                        }
                        className="gamification-exhibitor-tile-image"
                      />
                    </div>
                    <div
                      className={`${
                        element.voted
                          ? 'gamification-exhibitor-is-voted gamification-exhibitor-is-voted--active'
                          : 'gamification-exhibitor-is-voted'
                      }`}
                    >
                      <span>
                        {element.name === 'Orion media' ? 'Orion Media Group' : element.name}
                      </span>

                      <span>{element.voted ? 'Zagłosowano' : 'Nie zagłosowano'}</span>
                    </div>
                  </div>
                )
              })}
          </div>
        </div>
      </>
    )
  return null
}
