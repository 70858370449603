import React, { FC, useState } from 'react'
import '../styles.scss'
import LoginInput from '../../LoginInput'
import { fetcher } from '../../../services/api'
import { GAMIFICATION_URL } from '../../../../config'
import { KeyedMutator } from 'swr'
import { GamificationExhibitorsProps } from '..'
import dashboardMessage from '../../Message'
import { DashboardMessageTypes } from '../../../utils/enums'

type GamificationSlugProps = {
  token: string
  fetchGamification: KeyedMutator<GamificationExhibitorsProps>
}

export const GamificationSlug: FC<GamificationSlugProps> = ({ token, fetchGamification }) => {
  const [slugForm, setSlugForm] = useState({ eventSlug: '' })

  return (
    <div className="gamification-wrapper">
      <span>Wpisz slug eventu dla którego chcesz włączyć grywalizację:</span>
      <LoginInput
        label="Slug"
        name="eventSlug"
        placeholder="Slug"
        type="text"
        onChange={setSlugForm}
      />
      <button
        className="gamification-button gamification-button--form"
        onClick={() =>
          fetcher(`${GAMIFICATION_URL}/exhibitor/import`, token, 'POST', slugForm)
            .finally(() => fetchGamification())
            .then((response) => {
              if (response.result === 1) {
                dashboardMessage(
                  DashboardMessageTypes.success,
                  'Wysłano pomyślnie! Grywalizacja ustawiona na podany slug.',
                )
              } else {
                dashboardMessage(
                  DashboardMessageTypes.error,
                  'Coś poszło nie tak, czy na pewno podałeś prawidłowy slug?',
                )
              }
            })
        }
      >
        Wyślij
      </button>
    </div>
  )
}
