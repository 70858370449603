import { ActionReducerMapBuilder, createSlice, AsyncThunk } from '@reduxjs/toolkit'
import { Languages } from '../../utils/enums'
import {
  asyncFetchEvents,
  asyncFetchAgendaItem,
  asyncFetchAgendaItemAudienceQuestions,
  asyncFetchArticle,
  asyncFetchAudienceQuestions,
  asyncFetchEventContent,
  asyncFetchExhibitor,
  asyncFetchPeopleItem,
  asyncFetchPeopleNetworking,
  asyncPostAudienceQuestion,
} from './async'
import {
  AgendaItemsProps,
  ArticlesProps,
  AudienceQuestion,
  Dashboard,
  Events,
  MapPointsProps,
  PeopleProps,
} from './interfaces'

interface DashboardState {
  loading: boolean
  error: boolean
  width: number
  data: Dashboard | null
  events: Events[] | null
  peopleNetworking: any | null
  audienceQuestions: AudienceQuestion[] | null
  language: Languages
}

const initialState: DashboardState = {
  error: false,
  loading: false,
  width: 1920,
  data: null,
  events: null,
  peopleNetworking: null,
  audienceQuestions: null,
  language: Languages.pl,
}

//TODO: https://github.com/Microsoft/TypeScript/issues/1213 replace any

const asyncActions = <T extends AsyncThunk<any, any, any>>(
  asyncFetch: T,
  builder: ActionReducerMapBuilder<DashboardState>,
) => {
  builder.addCase(asyncFetch.pending, (state) => {
    state.loading = true
    state.error = false
  })
  builder.addCase(asyncFetch.rejected, (state) => {
    state.loading = false
    state.error = true
  })
}

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setWidth(state, action) {
      state.width = action.payload
    },
    changeLang(state, action) {
      state.language = action.payload
      window.localStorage.setItem('lang', action.payload)
    },
  },
  extraReducers: (builder) => {
    asyncActions(asyncFetchEventContent, builder)
    builder.addCase(asyncFetchEventContent.fulfilled, (state, action) => {
      state.loading = false
      window.localStorage.setItem('event_lang', action.payload.configuration.languages)
      state.data = action.payload
    })

    asyncActions(asyncFetchEvents, builder)
    builder.addCase(asyncFetchEvents.fulfilled, (state, action) => {
      state.loading = false
      state.events = action.payload.sort((a, b) => {
        const dateA = new Date(a.date_start || '')
        const dateB = new Date(b.date_start || '')
        return dateA.getTime() - dateB.getTime()
      })
    })

    asyncActions(asyncFetchArticle, builder)
    builder.addCase(asyncFetchArticle.fulfilled, (state, action) => {
      state.loading = false
      if (state.data !== null) {
        state.data.articles.map((element: ArticlesProps) => {
          const { id, content } = action.payload
          if (element.id === id) {
            element.content = content
          }
          return element
        })
      }
    })

    asyncActions(asyncFetchExhibitor, builder)
    builder.addCase(asyncFetchExhibitor.fulfilled, (state, action) => {
      state.loading = false
      if (state.data !== null) {
        state.data.map_points.map((element: MapPointsProps) => {
          const { id, content } = action.payload
          if (element.id === id) {
            element.content = content
          }
          return element
        })
      }
    })

    asyncActions(asyncFetchAgendaItem, builder)
    builder.addCase(asyncFetchAgendaItem.fulfilled, (state, action) => {
      state.loading = false
      if (state.data !== null) {
        state.data.agenda_items.map((element: AgendaItemsProps) => {
          const { id, attachments, content } = action.payload
          if (element.id === id) {
            element.attachments = attachments
            element.content = content
          }
          return element
        })
      }
    })

    asyncActions(asyncFetchAudienceQuestions, builder)
    builder.addCase(asyncFetchAudienceQuestions.fulfilled, (state, action) => {
      state.loading = false

      const { questions } = action.payload
      state.audienceQuestions = questions
    })

    asyncActions(asyncFetchAgendaItemAudienceQuestions, builder)
    builder.addCase(asyncFetchAgendaItemAudienceQuestions.fulfilled, (state, action) => {
      state.loading = false
      if (state.data !== null) {
        state.data.agenda_items.map((element: AgendaItemsProps) => {
          const { questions } = action.payload
          const { arg } = action.meta
          if (element.id === Number(arg)) {
            element.audience_questions_agenda_item = questions
          }
          return element
        })
      }
    })

    asyncActions(asyncFetchPeopleItem, builder)
    builder.addCase(asyncFetchPeopleItem.fulfilled, (state, action) => {
      state.loading = false
      if (state.data !== null) {
        state.data.people.map((element: PeopleProps) => {
          const { id, content } = action.payload
          if (element.id === id) {
            element.content = content
          }
          return element
        })
      }
    })

    asyncActions(asyncFetchPeopleNetworking, builder)
    builder.addCase(asyncFetchPeopleNetworking.fulfilled, (state, action) => {
      state.loading = false
      state.peopleNetworking = action.payload?.people
    })

    asyncActions(asyncPostAudienceQuestion, builder)
    builder.addCase(asyncPostAudienceQuestion.fulfilled, (state, action) => {
      state.loading = false
    })
  },
})
export const { setWidth, changeLang } = dashboardSlice.actions
export default dashboardSlice.reducer
