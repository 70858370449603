import React, { useState } from 'react'
import { getTimeFromDate, getDayName, formatDate } from '../../utils/functions'
import { AgendaItemsProps } from '../../redux/dashboard/interfaces'
import searchSubstring from '../../helpers/searchSubstring'
import translate from '../../translate'
import { useDispatch, useSelector } from 'react-redux'
import { selectLanguage, selectSlug } from '../../redux/dashboard/selectors'
import Icon from '../Icon'
import { Icons, IconsColor, IconsFillType } from '../../utils/enums'
import './styles.scss'
import { selectUser, selectUserFavorites, selectUserToken } from '../../redux/user/selectors'
import { asyncDeleteFavorites, asyncPostFavorites } from '../../redux/user/async'

const SchedulerItem = ({
  active,
  data,
  isLiked,
}: {
  active?: boolean
  data: AgendaItemsProps | undefined
  isLiked?: boolean
}) => {
  const [isLikedNow, setIsLikedNow] = useState(false)
  const dispatch = useDispatch()
  const slug = useSelector(selectSlug)
  const token = useSelector(selectUserToken)
  const user = useSelector(selectUser)

  const saveToFavorites = (id?: number) => {
    if (id && slug && token && isLikedNow === false) {
      dispatch(
        asyncPostFavorites({
          id: id,
          model: 'AgendaItem',
          slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
        }),
      )
      setIsLikedNow(true)
    }
  }

  const deleteFromFavorites = (id?: number) => {
    if (id && slug && token && (isLikedNow === true || isLiked)) {
      dispatch(
        asyncDeleteFavorites({
          id: id,
          model: 'AgendaItem',
          slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
        }),
      )
      setIsLikedNow(false)
    }
  }

  return (
    <div className={active ? 'active' : undefined}>
      <div className="scheduler-item-wrapper">
        {data?.icon_url ? (
          <img src={data?.icon_url} alt={data?.name} />
        ) : (
          <Icon
            name={Icons.board}
            color={IconsColor.xiaomi}
            hoverColor={IconsColor.xiaomi}
            type={IconsFillType.stroke}
          />
        )}

        <span>
          {data?.name} <br />
          {data?.datetime_start && data.datetime_end && (
            <time>
              {getTimeFromDate(data.datetime_start)} - {getTimeFromDate(data.datetime_end)}
            </time>
          )}
        </span>
      </div>
      {user && (
        <div className="icons-wrapper">
          {/* <Icon
          name={Icons.calendar}
          color={IconsColor.grey}
          hoverColor={IconsColor.xiaomi}
          type={IconsFillType.stroke}
        />
        <Icon
          name={Icons.bell}
          color={IconsColor.grey}
          hoverColor={IconsColor.xiaomi}
          type={IconsFillType.stroke}
        /> */}
          <button
            className="exhibitor-bar-detailed__heart"
            onClick={() =>
              isLiked || isLikedNow ? deleteFromFavorites(data?.id) : saveToFavorites(data?.id)
            }
          >
            {isLiked || isLikedNow ? (
              <Icon name={Icons.heartFull} color={IconsColor.tesla} type={IconsFillType.fill} />
            ) : (
              <Icon
                name={Icons.heartOutlined}
                color={IconsColor.grey}
                type={IconsFillType.stroke}
              />
            )}
          </button>
        </div>
      )}
    </div>
  )
}

const Scheduler: React.FC<{
  day: number
  data: AgendaItemsProps[] | undefined
  selected: number | null
  onChange: (id: number) => void
  search?: string
  isFavorites?: boolean
}> = ({ day, data, selected, onChange, search, isFavorites }) => {
  const lang = useSelector(selectLanguage)
  const actualDay =
    data && data.length > 0 ? new Date(data[0].datetime_start).getDay() : new Date().getDay()

  data?.sort((a: AgendaItemsProps, b: AgendaItemsProps) => {
    const aDate = new Date(a.datetime_start).getTime()
    const bDate = new Date(b.datetime_start).getTime()
    return aDate - bDate
  })

  const favorites = useSelector(selectUserFavorites)

  return (
    <section className="scheduler__content">
      <ul className="scheduler__main-list">
        <li className="scheduler__main-list__item">
          <div>
            <span>
              {translate('day')} {day}
            </span>
            <time>
              {getDayName(actualDay, lang)}{' '}
              {data && data.length > 0 && formatDate(data[0].datetime_start)}
            </time>
          </div>
          <ul>
            {data
              ?.filter((element) => {
                if (isFavorites) {
                  return favorites.favourite_agenda_items_ids
                    .map((el) => String(el))
                    .includes(String(element.id))
                }
                return element
              })
              ?.filter((element) => {
                return searchSubstring(element.name, search)
              })
              .map((el: AgendaItemsProps) => {
                return (
                  <React.Fragment key={el.id}>
                    <li className="time-only">
                      <time>{getTimeFromDate(el.datetime_start)}</time>
                    </li>
                    <li onClick={() => onChange(el.id)}>
                      <SchedulerItem
                        active={el.id === selected}
                        data={el}
                        isLiked={favorites.favourite_agenda_items_ids
                          .map((el) => String(el))
                          .includes(String(el.id))}
                      />
                    </li>
                  </React.Fragment>
                )
              })}
            <li className="time-only">
              <time>
                {data && data.length > 0 && getTimeFromDate(data[data.length - 1].datetime_end)}
              </time>
            </li>
          </ul>
        </li>
      </ul>
    </section>
  )
}

export default Scheduler
