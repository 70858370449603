import { createIntl, createIntlCache } from 'react-intl'
import { LANGUAGE } from './services/consts'
import { Languages } from './utils/enums'

export const messages = {
  en: {
    contact: 'Contact',
    agenda: 'Agenda',
    gallery: 'Gallery',
    access: 'Access',
    exhibitors: 'Exhibitors',
    exhibitors_map: 'Exhibitors map',
    favorites: 'Favorites',
    people: 'People',
    networking: 'Networking',
    networking_login: 'Networking - for logged in users',
    show_me_on_networking: 'Show me on networking',
    notes: 'Notes',
    articles: 'Articles',
    back: 'Back',
    title: 'Title',
    content: 'Content',
    save: 'Save',
    copied_URL: 'URL copied to clipboard',
    materials: 'Materials',
    add_note: 'Add note',
    edit_note: 'Edit note',
    settings: 'Settings',
    logout: 'Logout',
    sign_in: 'Sign in',
    sign_in_text:
      'Log in with the details provided when creating an account or registering for any event',
    login: 'Login',
    no_account: "You don't have an account?",
    no_login: 'Go to login page',
    no_register: "You dont' want to register?",
    no_register_welcome: 'Go to free events',
    have_account: 'You have an account?',
    register: 'Sign up',
    not_remember_password: "You don't you remember the password?",
    edit_profile: 'Edit profile',
    settings_agreements: 'Settings and agreements',
    agreements: 'Agreements',
    change_password: 'Change password',
    change_password_text: 'Set new password for your account',
    password: 'Password',
    repeat_password: 'Repeat password',
    new_password: 'New password',
    repeat_new_password: 'Repeat new password',
    remind_password: 'Remind password',
    remind_password_text:
      'A link to reset your password will be sent to your registered email address',
    open_menu: 'Open menu',
    name: 'Name',
    surname: 'Surname',
    position: 'Position',
    company: 'Company',
    phone_number: 'Phone number',
    phone_number_err: 'Phone number should have 9 digits',
    delete_account: 'Delete account',
    delete_account_text:
      'After deleting your account, you will no longer have access to any events',
    delete_account_modal_text: 'Are you sure you want to delete your account?',
    email: 'Email',
    cancel: 'Cancel',
    confirm: 'Confirm',
    required: 'Required',
    see_more: 'See more',
    all_events: 'All events',
    fav_events: 'Favorite events',
    archive_events: 'Archive events',
    enter_code: 'Enter code',
    add_event: 'Add event',
    manage_events: 'Manage events',
    stand: 'Stand',
    information: 'Information',
    chat: 'Chat',
    streaming: 'Streaming',
    live: 'Live',
    video_call: 'Video call',
    attachments: 'Attachments',
    localization: 'Localization',
    write_a_message: 'Write a message',
    send: 'Send',
    networking_is_off:
      'You are not visible in networking, update your settings to chat with people',
    ask_question: 'Ask question',
    your_question: 'Your question',
    day: 'Day',
    poll: 'Poll',
    poll_unavailable: 'Poll unavailable',
    enter_only_digits: 'Enter only digits',
    description: 'Description',
    show_all: 'Show all',
    map: 'Map',
    login_create_event_1: 'Do you want to create your event?',
    login_create_event_2: 'See how easy it is!',
    login_option_1: 'Log in',
    login_option_2: 'Choose a package',
    login_option_3: 'Pay for or provide the code',
    login_option_4: 'Start the event',
    login_help_1: 'Do you have questions or need help?',
    login_help_2: 'Contact us!',
    welcome: 'Hello at meetinga!',
    post_poll: 'Thank you for voting!',
    post_audience:
      'After the question is approved by the administrator, it will be displayed in the application.',
    post_audience_like: 'You liked the question.',
    post_audience_dislike: "You don't like this question anymore.",
    register_event: 'Registered for the event. Click on the tile again to enter it.',
    password_reset: 'Password reset email has been sent',
    password_reset_confirm: 'New password set correctly. You can log in!',
    password_change: 'Password updated.',
    user_details: 'Data updated.',
    logout_success: 'Logged out.',
    account_deleted: 'Account has been deleted.',
    register_success: 'Successfully registered.',
    login_success: 'Logged in successfully.',
    register_redirect: 'Register by clicking on the tile on the left to access the event.',
    token_refresh: 'Log in again with the correct details.',
    empty_code: 'The code field cannot be empty.',
    password_diff: 'The entered passwords are different!',
    fav: 'Favorite exhibitors',
    fav_agenda: 'Favorite agenda',
    agenda_link: 'Information about the event',
    agenda_link2: 'is located on an external website',
    agenda_link_open: 'Open',
    agenda_link_question: 'Do you want to open this page?',
    send_invitation: 'Send invitation',
    inv_accept: 'Accept the invitation',
    inv_reject: 'Reject invitation',
    inv_sent: 'Invitation sent',
    inv_accepted: 'Invitation accepted',
    inv_rejected: 'Invitation rejected',
    inv_rejected_by_you: 'Invitation rejected by you',
    inv_note: 'Note for invitation:',
    inv_reject_note: 'Rejection reason:',
    inv_received: 'Invitation received',
    available_rooms: 'Available people',
    my_rooms: 'My conversations',
    general_room: 'General chat',
  },
  pl: {
    contact: 'Kontakt',
    agenda: 'Agenda',
    gallery: 'Galeria',
    access: 'Dostęp',
    exhibitors: 'Wystawcy',
    exhibitors_map: 'Mapa wystawców',
    favorites: 'Ulubione',
    people: 'Prelegenci',
    networking: 'Networking',
    networking_login: 'Networking - dla zalogowanych',
    show_me_on_networking: 'Pokaż mnie w networkingu',
    articles: 'Artykuły',
    back: 'Powrót',
    title: 'Tytuł',
    content: 'Treść',
    save: 'Zapisz',
    notes: 'Notatki',
    copied_URL: 'Skopiowano URL do schowka',
    materials: 'Materiały',
    add_note: 'Dodaj notatkę',
    edit_note: 'Edytuj notatkę',
    settings: 'Ustawienia',
    logout: 'Wyloguj',
    sign_in: 'Zaloguj się',
    sign_in_text:
      'Zaloguj się z danymi podanymi przy zakładaniu konta lub rejestracji na dowolne wydarzenie',
    login: 'Login',
    no_account: 'Nie masz konta?',
    no_login: 'Przejdź do logowania',
    no_register: 'Nie chcesz się rejestrować?',
    no_register_welcome: 'Przejdź do darmowych wydarzeń',
    have_account: 'Masz już konto?',
    register: 'Zarejestruj się',
    not_remember_password: 'Nie pamiętasz hasła?',
    edit_profile: 'Edytuj profil',
    settings_agreements: 'Ustawienia i zgody',
    agreements: 'Zgody',
    change_password: 'Zmień hasło',
    change_password_text: 'Ustaw nowe hasło do swojego konta',
    password: 'Hasło',
    repeat_password: 'Powtórz hasło',
    new_password: 'Nowe hasło',
    repeat_new_password: 'Powtórz nowe hasło',
    remind_password: 'Przypomnij hasło',
    remind_password_text:
      'Link do resetowania hasła zostanie wysłany na zarejestrowany adres email',
    open_menu: 'Otwórz menu',
    name: 'Imię',
    surname: 'Nazwisko',
    position: 'Stanowisko',
    company: 'Firma',
    phone_number: 'Numer telefonu',
    phone_number_err: 'Numer telefonu powinien zawierać 9 cyfr',
    delete_account: 'Usuń konto',
    delete_account_text: 'Po usunięciu konta nie będziesz miał już dostępów do żadnych wydarzeń',
    delete_account_modal_text: 'Czy na pewno chcesz usunąć swoje konto?',
    email: 'Adres email',
    cancel: 'Anuluj',
    confirm: 'Zatwierdź',
    required: 'Wymagane',
    see_more: 'Zobacz więcej',
    all_events: 'Aktualne eventy',
    fav_events: 'Ulubione eventy',
    archive_events: 'Archiwalne eventy',
    enter_code: 'Wpisz kod',
    add_event: 'Dodaj event',
    manage_events: 'Zarządzaj eventami',
    stand: 'Stoisko',
    information: 'Informacje',
    chat: 'Chat',
    streaming: 'Streaming',
    live: 'Na żywo',
    video_call: 'Wideo rozmowa',
    attachments: 'Załączniki',
    localization: 'Lokalizacja',
    write_a_message: 'Napisz wiadomość',
    send: 'Wyślij',
    networking_is_off:
      'Masz wyłączony networking, zaktualizuj swoje ustawienia żeby czatować z ludźmi',
    ask_question: 'Zadaj pytanie',
    your_question: 'Twoje pytanie',
    day: 'Dzień',
    poll: 'Ankieta',
    poll_unavailable: 'Ankieta niedostępna',
    enter_only_digits: 'Wprowadź tylko cyfry',
    description: 'Opis',
    show_all: 'Pokaż wszystko',
    map: 'Mapa',
    login_create_event_1: 'Chcesz utworzyć swoje wydarzenie?',
    login_create_event_2: 'Zobacz jakie to proste!',
    login_option_1: 'Zaloguj się',
    login_option_2: 'Wybierz pakiet',
    login_option_3: 'Opłać lub podaj kod',
    login_option_4: 'Uruchom wydarzenie',
    login_help_1: 'Masz pytania lub potrzebujesz pomocy?',
    login_help_2: 'Skontaktuj się z nami!',
    welcome: 'Witaj w meetindze!',
    post_poll: 'Dziękujemy za oddanie głosu!',
    post_audience: 'Po zaakceptowaniu pytania przez administratora wyświetli się ona w aplikacji.',
    post_audience_like: 'Polubiono pytanie.',
    post_audience_dislike: 'Już nie lubisz tego pytania.',
    register_event:
      'Zarejestrowano na wydarzenie. Kliknij na kafelek jeszcze raz żeby w nie wejść.',
    password_reset: 'Wysłano e-mail z możliwością zresetowania hasła',
    password_reset_confirm: 'Nowe hasło ustawione poprawnie. Możesz się zalogować!',
    password_change: 'Zaktualizowano hasło.',
    user_details: 'Zaktualizowano dane.',
    logout_success: 'Wylogowano pomyślnie.',
    account_deleted: 'Konto zostało usunięte.',
    register_success: 'Zarejestrowano pomyślnie.',
    login_success: 'Zalogowano pomyślnie.',
    register_redirect:
      'Zarejestruj się klikając w kafelek po lewej stronie, aby uzyskać dostęp do eventu.',
    token_refresh: 'Zaloguj się ponownie podając prawidłowe dane.',
    empty_code: 'Pole z kodem nie może być puste.',
    password_diff: 'Wprowadzone hasła różnią się od siebie!',
    fav: 'Ulubieni wystawcy',
    fav_agenda: 'Ulubiona agenda',
    agenda_link: 'Informacje o wydarzeniu',
    agenda_link2: 'umieszczone są na zewnętrznej stronie internetowej',
    agenda_link_open: 'Otwórz',
    agenda_link_question: 'Czy chcesz otworzyć tę stronę?',
    send_invitation: 'Wyślij zaproszenie',
    inv_accept: 'Zaakceptuj zaproszenie',
    inv_reject: 'Odrzuć zaproszenie',
    inv_sent: 'Wysłano zaproszenie',
    inv_accepted: 'Zaakceptowano zaproszenie',
    inv_rejected: 'Odrzucono zaproszenie',
    inv_rejected_by_you: 'Odrzucono zaproszenie przez Ciebie',
    inv_note: 'Notatka do zaproszenia:',
    inv_reject_note: 'Powód odrzucenia:',
    inv_received: 'Otrzymano zaproszenie',
    available_rooms: 'Dostępne osoby',
    my_rooms: 'Moje rozmowy',
    general_room: 'Czat ogólny',
  },
}

const cache = createIntlCache()
const lang = window.localStorage.getItem('lang') || LANGUAGE

let int = createIntl(
  {
    locale: lang || 'pl',
    messages: lang === 'en' || lang === 'pl' ? messages[lang] : messages['pl'],
  },
  cache,
)

export function changeLanguage(lang: Languages) {
  const newIntl = createIntl(
    {
      locale: lang,
      messages: messages[lang],
    },
    cache,
  )
  int = newIntl
}

const translate = (id: string, values?: {}) => {
  return int.formatMessage({ id }, values)
}

export default translate
