import React, { FC, useState } from 'react'
import Modal from 'react-modal'
import { GAMIFICATION_URL } from '../../../../../config'
import { fetcher } from '../../../../services/api'
import { CurrentExhibitorProps, GamificationExhibitorsProps } from '../..'
import { KeyedMutator } from 'swr'
import dashboardMessage from '../../../Message'
import { DashboardMessageTypes } from '../../../../utils/enums'
import { GamificationFetchSlugProps } from '../../GamificationAdmin'

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    zIndex: 10000,
  },
}

Modal.setAppElement('#root')

type GamificationResetModalProps = {
  token: string
  fetchGamification: KeyedMutator<GamificationExhibitorsProps>
  fetchSlug: KeyedMutator<GamificationFetchSlugProps>
  setCurrentExhibitor: (exhibitor: CurrentExhibitorProps) => void
}

export const GamificationResetModal: FC<GamificationResetModalProps> = ({
  token,
  fetchGamification,
  fetchSlug,
  setCurrentExhibitor,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false)

  return (
    <>
      <button
        onClick={() => setIsOpen(true)}
        className="gamification-button gamification-button--red"
      >
        Zresetuj grę
      </button>
      <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
        <div className="asking-modal">
          <h2>Czy na pewno chcesz zresetować grę?</h2>
          <p>Pamiętaj żeby zapisać wcześniej zapisać wyniki!</p>
          <div>
            <button className="gamification-button" onClick={() => setIsOpen(false)}>
              Anuluj
            </button>
            <button
              className="gamification-button"
              onClick={() => {
                fetcher(`${GAMIFICATION_URL}/exhibitor/reset`, token, 'PATCH')
                  .finally(() => {
                    fetchGamification()
                    fetchSlug()
                  })
                  .then((response) => {
                    if (response.result === 1) {
                      dashboardMessage(DashboardMessageTypes.success, 'Zresetowano grywalizację.')
                    } else {
                      dashboardMessage(
                        DashboardMessageTypes.error,
                        'Coś poszło nie tak z resetem grywalizacji.',
                      )
                    }
                  })
                setIsOpen(false)
                setCurrentExhibitor({ id: 0, name: 'Brak' })
              }}
            >
              Zresetuj
            </button>
          </div>
        </div>
      </Modal>
    </>
  )
}
