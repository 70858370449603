import React, { FC, useCallback, useMemo, useState } from 'react'
import Modal from 'react-modal'
import { Link, useHistory } from 'react-router-dom'
import { DashboardMessageTypes, Icons, IconsColor, IconsFillType } from '../../utils/enums'
import Icon from '../Icon'
import { isAfter } from 'date-fns'
import { Dropdown, Menu } from 'antd'
// import arrowDown from '../../../images/arrow-down.svg'
import dashboardMessage from '../../components/Message'
import QuestionModal from '../QuestionModal'

import { useDispatch, useSelector } from 'react-redux'
import {
  selectEventConfiguration,
  selectEvents,
  selectLanguage,
  selectMenuItems,
  selectSlug,
} from '../../redux/dashboard/selectors'
import { Events, MenuProps } from '../../redux/dashboard/interfaces'
import { asyncFetchEventContent } from '../../redux/dashboard/async'
import { addEventToFavorites, deleteEventFromFavorites } from '../../redux/user/slice'
import { selectEventsFavorites, selectUser, selectUserToken } from '../../redux/user/selectors'
import { asyncRegisterEvent } from '../../redux/user/async'
import translate from '../../translate'
import { getEventLanguage } from '../../utils/language'
import { GamificationLink } from '../Gamification/GamificationLink'
import './styles.scss'

export interface Props {
  activePage?: string
  className?: string
}

const Sidebar: FC<Props> = ({ activePage = '', className }) => {
  const menuItems = useSelector(selectMenuItems)
  const user = useSelector(selectUser)
  const token = useSelector(selectUserToken)
  const slug = useSelector(selectSlug)
  const event = useSelector(selectEventConfiguration)
  const events = useSelector(selectEvents)
  const eventsFavorites = useSelector(selectEventsFavorites)
  const currentLanguage = useSelector(selectLanguage)

  const [activeDropdown, setActiveDropdown] = useState<string>('all')
  const [, setActiveEvent] = useState<null | string>(event?.name || null)
  const [modalIsOpenLogin, setIsOpenLogin] = useState(false)
  const [modalIsOpen, setIsOpen] = useState(false)
  const [modalLinkIsOpen, setLinkIsOpen] = useState({ link: '', name: '', logo: '' })
  const [eventCode, setEventCode] = useState<string>('')
  const [eventSlug, setEventSlug] = useState<string>('')

  const history = useHistory()

  const dispatch = useDispatch()

  const NO_GAMIFICATION = useMemo(() => {
    if (user?.user_groups1?.length) {
      return user?.user_groups1?.some(
        (element) => element.name === 'Prelegenci' || element.name === 'Online',
      )
    }
    return true
  }, [user])

  const sendRegisterCode = (code: string) => {
    if (eventCode.length === 0) {
      dashboardMessage(DashboardMessageTypes.warning, translate('empty_code'))
    } else {
      dispatch(
        asyncRegisterEvent({
          slug: eventSlug,
          additionalHeaders: { Authorization: `JWT ${token}` },
          body: { code: code },
        }),
      )
      setIsOpen(false)
    }
  }

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
    },
  }

  const handleFetchEvent = useCallback(
    (slug: string, event: Events) => {
      const changeEvent = (slug: string, name: string) => {
        dispatch(
          asyncFetchEventContent({
            slug: slug,
            language: getEventLanguage(events, slug, currentLanguage),
          }),
        )
        setActiveEvent(name)
      }

      if (event.event_registration_enabled && !event.event_registration_without_code_enabled) {
        setIsOpenLogin(true)
      } else {
        history.push(`/event/${slug}/agenda`)
        changeEvent(slug, event.name)
      }
    },
    [dispatch, history, currentLanguage, events],
  )

  const handleFetchAndRegisterEvent = useCallback(
    (slug: string, event: Events) => {
      const changeEvent = (slug: string, name: string) => {
        dispatch(
          asyncFetchEventContent({
            slug: slug,
            additionalHeaders: { Authorization: `JWT ${token}` },
            language: getEventLanguage(events, slug, currentLanguage),
          }),
        )
        setActiveEvent(name)
      }

      if (
        event.event_registration_enabled &&
        !event.event_registration_without_code_enabled &&
        !user?.registered_events.some((obj) => obj.name === event.name)
      ) {
        setIsOpen(true)
        setEventSlug(slug)
      } else {
        if (!user?.registered_events.some((obj) => obj.name === event.name)) {
          dispatch(
            asyncRegisterEvent({
              slug: slug,
              additionalHeaders: { Authorization: `JWT ${token}` },
              body: {},
            }),
          )
        } else {
          history.push(`/event/${slug}/agenda`)
          changeEvent(slug, event.name)
        }
      }
    },
    [dispatch, history, user?.registered_events, token, currentLanguage, events],
  )

  Modal.setAppElement('#root')

  const userMenu = (
    <Menu>
      <Menu.Item key="0" onClick={() => setActiveDropdown('all')}>
        <small>{translate('all_events')}</small>
      </Menu.Item>
      <Menu.Item key="1" onClick={() => setActiveDropdown('favorites')}>
        <small>{translate('fav_events')}</small>
      </Menu.Item>
      <Menu.Item key="2" onClick={() => setActiveDropdown('archive')}>
        <small>{translate('archive_events')}</small>
      </Menu.Item>
    </Menu>
  )

  return (
    <>
      <aside className={className ? `sidebar sidebar__${className}` : 'sidebar'}>
        <div className="sidebar__content">
          <div>
            <label>
              <Icon
                name={Icons.meetingaIcon}
                color={IconsColor.white}
                hoverColor={IconsColor.xiaomi}
                type={IconsFillType.fill}
              />
            </label>
            {!NO_GAMIFICATION && (
              <div className="sidebar-gamification-wrapper">
                <GamificationLink />
              </div>
            )}
            {/* <div className="sidebar__event-buttons">
              <button onClick={() => history.push('/add-event')} />
              <button onClick={() => window.open('https://appsumo.meetinga.com/', '_blank')}>
                <span>{translate('add_event')}</span>
                <Icon
                  name={Icons.plus}
                  color={IconsColor.white}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
              </button>
              <button
                onClick={() => window.open('https://beckakademia.meetinga.com/pl/admin/', '_blank')}
              >
                <span>{translate('manage_events')}</span>
                <Icon
                  name={Icons.rightUp}
                  color={IconsColor.white}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
              </button>
            </div> */}
            <section>
              {event && (
                <ul className="sidebar__list">
                  <li className="sidebar__list-buttons">
                    <div className="sidebar__current-event">
                      <div className="sidebar__list-buttons-wrapper">
                        <img src={event.logo_url} alt={event.name} />
                        {eventsFavorites.includes(event.name) ? (
                          <button onClick={() => dispatch(deleteEventFromFavorites(event.name))}>
                            <Icon
                              name={Icons.heartFull}
                              color={IconsColor.tesla}
                              type={IconsFillType.fill}
                            />
                          </button>
                        ) : (
                          <button onClick={() => dispatch(addEventToFavorites(event.name))}>
                            <Icon
                              name={Icons.heartOutlined}
                              color={IconsColor.grey}
                              type={IconsFillType.stroke}
                            />
                          </button>
                        )}
                      </div>
                      <span>{event.name}</span>
                    </div>
                  </li>

                  {menuItems &&
                    menuItems.map((element: MenuProps) => {
                      if (element.type === 'Dashboard') return null
                      if (element.title === 'Grywalizacja') {
                        if (NO_GAMIFICATION) return null
                        return (
                          <Link key={element.id} to={'/gamification'}>
                            <li className={activePage === 'gamification' ? 'active' : undefined}>
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                      }
                      if (element.custom_url) {
                        return (
                          // eslint-disable-next-line react/jsx-no-target-blank
                          <a key={element.id} href={element.custom_url} target="_blank">
                            <li>{element.title || element.type}</li>
                          </a>
                        )
                      } else if (element.type === 'AskQuestion') {
                        return (
                          <li key={element.id}>
                            <QuestionModal type="text" id={element.id} isAgendaItem={false} />
                          </li>
                        )
                      } else if (element.type === 'Notes') {
                        return (
                          <Link key={element.id} to={'/notes'}>
                            <li className={activePage === 'notes' ? 'active' : undefined}>
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                      } else if (element.article_id || element.articles_category_id) {
                        return (
                          <Link
                            key={element.id}
                            to={
                              element.article_id
                                ? `/event/${slug}/article/${element.article_id}`
                                : `/event/${slug}/articles/${element.articles_category_id}`
                            }
                          >
                            <li
                              className={
                                activePage === `articles/${element.articles_category_id}` ||
                                activePage === `article/${element.article_id}`
                                  ? 'active'
                                  : undefined
                              }
                            >
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                      } else
                        return (
                          <Link
                            key={element.id}
                            to={`/event/${slug}/${element.type.toLowerCase()}`}
                          >
                            <li
                              className={
                                activePage === element.type.toLowerCase() ? 'active' : undefined
                              }
                            >
                              {element.title || element.type}
                            </li>
                          </Link>
                        )
                    })}

                  {event.networking_enabled && user && (
                    <Link to={`/event/${slug}/networking`}>
                      <li className={activePage === 'networking' ? 'active' : undefined}>
                        {translate('networking')}
                      </li>
                    </Link>
                  )}

                  {event.networking_enabled && !user && (
                    <li
                      className={activePage === 'networking' ? 'active' : undefined}
                      style={{ cursor: 'not-allowed' }}
                    >
                      {translate('networking_login')}
                    </li>
                  )}
                </ul>
              )}
              {events && (
                <>
                  <Dropdown overlay={userMenu} trigger={['click']}>
                    <button className="sidebar__dropdown">
                      <small>
                        {activeDropdown === 'all'
                          ? translate('all_events')
                          : activeDropdown === 'favorites'
                          ? translate('fav_events')
                          : translate('archive_events')}
                      </small>
                      <Icon
                        name={Icons.downArrow}
                        color={IconsColor.white}
                        hoverColor={IconsColor.white}
                        type={IconsFillType.fill}
                      />
                    </button>
                  </Dropdown>
                  <ul className="sidebar__sponsors">
                    {events.map((element) => {
                      if (
                        (activeDropdown === 'favorites' &&
                          eventsFavorites.includes(element.name)) ||
                        (activeDropdown === 'archive' &&
                          element.date_end &&
                          isAfter(new Date(), new Date(element.date_end))) ||
                        (activeDropdown === 'all' &&
                          !(element.date_end && isAfter(new Date(), new Date(element.date_end))))
                      ) {
                        return (
                          <li
                            key={Math.random()}
                            onClick={() => {
                              if (element.event_as_link && element.event_link) {
                                setLinkIsOpen({
                                  link: element.event_link,
                                  name: element.name,
                                  logo: element.logo_url,
                                })
                              } else {
                                user
                                  ? handleFetchAndRegisterEvent(element.slug, element)
                                  : handleFetchEvent(element.slug, element)
                              }
                            }}
                            className={event?.name === element.name ? 'sponsor-active' : ''}
                          >
                            <div className="sidebar__sponsors-content">
                              <div className="sidebar__sponsors-content-image">
                                <img src={element.logo_url} alt={element.name} />
                              </div>
                              {eventsFavorites.includes(element.name) ? (
                                <button
                                  onClick={() => dispatch(deleteEventFromFavorites(element.name))}
                                >
                                  <Icon
                                    name={Icons.heartFull}
                                    color={IconsColor.tesla}
                                    type={IconsFillType.fill}
                                  />
                                </button>
                              ) : (
                                <button onClick={() => dispatch(addEventToFavorites(element.name))}>
                                  <Icon
                                    name={Icons.heartOutlined}
                                    color={IconsColor.grey}
                                    type={IconsFillType.stroke}
                                  />
                                </button>
                              )}
                            </div>
                            <span>{element.name}</span>
                            <span>{element.date_start}</span>
                          </li>
                        )
                      }
                      return null
                    })}
                  </ul>
                </>
              )}
            </section>
          </div>

          <div className="sidebar__settings">
            {/* <button>
            <Icon
              name={Icons.settings}
              color={IconsColor.white}
              hoverColor={IconsColor.xiaomi}
              type={IconsFillType.stroke}
            />
            Ustawienia
          </button> */}

            {!user && (
              <div className="sidebar__link__login">
                <span>
                  <Link className="login-page__link" to="/logowanie">
                    {translate('no_login')}
                  </Link>
                </span>
              </div>
            )}

            <img className="sidebar__logo" src="images/logo.svg" alt="logo" />
          </div>
        </div>
      </aside>
      {modalIsOpen && (
        <Modal isOpen={modalIsOpen} onRequestClose={() => setIsOpen(false)} style={customStyles}>
          <div className="asking-modal">
            <h2>{translate('enter_code')}</h2>
            <input value={eventCode} onChange={(e) => setEventCode(e.target.value)}></input>
            <div>
              <button className="asking-modal__send-btn" onClick={() => setIsOpen(false)}>
                {translate('cancel')}
              </button>
              <button
                className="asking-modal__send-btn"
                onClick={() => sendRegisterCode(eventCode)}
              >
                {translate('confirm')}
              </button>
            </div>
          </div>
        </Modal>
      )}

      {modalIsOpenLogin && (
        <Modal
          isOpen={modalIsOpenLogin}
          onRequestClose={() => setIsOpenLogin(false)}
          style={customStyles}
        >
          <div className="asking-modal">
            <p>Zaloguj się żeby mieć dostęp do tego wydarzenia</p>
            <div>
              <button className="asking-modal__send-btn" onClick={() => setIsOpenLogin(false)}>
                OK
              </button>
            </div>
          </div>
        </Modal>
      )}

      {modalLinkIsOpen.link && (
        <Modal
          isOpen={modalLinkIsOpen.link.length > 0}
          onRequestClose={() =>
            setLinkIsOpen({
              link: '',
              name: '',
              logo: '',
            })
          }
          style={customStyles}
        >
          <div className="asking-modal">
            {modalLinkIsOpen.logo && (
              <img
                className="asking-modal--agenda-logo"
                src={modalLinkIsOpen.logo}
                alt="exhibitor-logo"
              />
            )}
            <p>
              {translate('agenda_link')} {modalLinkIsOpen.name ?? modalLinkIsOpen.link}{' '}
              {translate('agenda_link2')}
            </p>

            <a href={modalLinkIsOpen.link} target="__blank" className="asking-modal--agenda-link">
              {modalLinkIsOpen.link}
            </a>

            <p>{translate('agenda_link_question')}</p>
            <div>
              <button
                className="asking-modal__send-btn"
                onClick={() =>
                  setLinkIsOpen({
                    link: '',
                    name: '',
                    logo: '',
                  })
                }
              >
                {translate('cancel')}
              </button>
              <button
                className="asking-modal__send-btn"
                onClick={() => window.open(modalLinkIsOpen.link, '_blank', 'noopener, noreferrer')}
              >
                {translate('agenda_link_open')}
              </button>
            </div>
          </div>
        </Modal>
      )}
    </>
  )
}

export default Sidebar
