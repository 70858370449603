import * as React from 'react'
import { SVGProps } from 'react'
export const GamificationRankingIcon = (props: SVGProps<SVGSVGElement>, color: 'string') => (
  <svg xmlns="http://www.w3.org/2000/svg" width={6.466} height={17.883} {...props}>
    <g fill="none" data-name="Group 34415">
      <g stroke={props.color} data-name="Ellipse 103">
        <circle cx={3.233} cy={3.233} r={3.233} stroke="none" />
        <circle cx={3.233} cy={3.233} r={2.733} />
      </g>
      <g data-name="Path 28772">
        <path d="M3.233 11.416A3.233 3.233 0 1 1 0 14.649a3.233 3.233 0 0 1 3.233-3.233Z" />
        <path
          fill={props.color}
          d="M3.233 12.416A2.236 2.236 0 0 0 1 14.649a2.236 2.236 0 0 0 2.233 2.233 2.236 2.236 0 0 0 2.233-2.233 2.236 2.236 0 0 0-2.233-2.233m0-1a3.233 3.233 0 1 1 0 6.466 3.233 3.233 0 0 1 0-6.466Z"
        />
      </g>
    </g>
  </svg>
)
