import React, { useState, useEffect, useRef } from 'react'
import Dashboard from '../../views/Dashboard'

import NavigationHeader from '../../components/NavigationHeader'
import MainTitle from '../../components/MainTitle'
import Person from '../../components/Person'
import NetworkingMessage from '../../components/NetworkingMessage'
import NetworkingAsideTile from '../../components/NetworkingAsideTile'
// import NetworkingSearch from '../../components/NetworkingSearch'
import NetworkingInput from '../../components/NetworkingInput'

import { useSelector, useDispatch } from 'react-redux'
import { selectPeopleNetworking, selectSlug, selectWidth } from '../../redux/dashboard/selectors'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import { asyncFetchPeopleNetworking } from '../../redux/dashboard/async'
import { IconsColor, Icons, IconsFillType } from '../../utils/enums'
import Icon from '../../components/Icon'
import translate from '../../translate'
import userImage from '../../../images/user.png'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { PeopleProps } from '../../redux/dashboard/interfaces'
import { Client } from '@twilio/conversations'
import axios from 'axios'
import { apiEvent } from '../../services/api'
import { Tab, Tabs } from '../../components/Tabs'
// import firebase from 'firebase/app'
// import '@firebase/messaging'

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />

const Networking: React.FC = () => {
  const dispatch = useDispatch()
  const slug = useSelector(selectSlug)
  const width = useSelector(selectWidth)
  const user = useSelector(selectUser)
  const userToken = useSelector(selectUserToken)
  const peopleNetworking = useSelector(selectPeopleNetworking)
  const [activeChat, setActiveChat] = useState<number | null>(null)
  const [sortedPeople, setSortedPeople] = useState<any[]>()
  const [, setRoom] = useState<string | null>(null)

  const [loading, setLoading] = useState(false)
  const [messages, setMessages] = useState<any[]>([])
  const [channel, setChannel] = useState<any>(undefined)
  const [twilioToken, setTwilioToken] = useState<string | null>(null)
  const [contactStatus, setContactStatus] = useState()
  const [text, setText] = useState('')
  const [myContacts, setMyContacts] = useState(false)

  const GENERAL_ROOM_ID = 99999

  let token = ''
  let scrollDiv = useRef<any>(null)

  const findDataById = () => {
    return peopleNetworking.find((element: any) => activeChat === element.id)
  }

  const findPersonById = (id: number) => {
    return peopleNetworking.find((element: any) => Number(id) === Number(element.id))
  }

  useEffect(() => {
    if (slug !== undefined) {
      if (myContacts)
        dispatch(
          asyncFetchPeopleNetworking({
            slug: slug,
            additionalHeaders: { Authorization: `JWT ${userToken}` },
            type: '?type=ACTION_TAKEN',
          }),
        )
      else {
        dispatch(
          asyncFetchPeopleNetworking({
            slug: slug,
            additionalHeaders: { Authorization: `JWT ${userToken}` },
          }),
        )
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [slug, myContacts])

  const tryJoinChannel = async (round?: string) => {
    setLoading(true)
    try {
      token = twilioToken ? twilioToken : await getToken(String(user?.id))
    } catch {
      throw new Error('Unable to get token, please reload this page')
    }

    // const client = await ChatAPI.Client.create(token.token)
    const client = await Client.create(token)

    client.on('tokenAboutToExpire', async () => {
      const token = twilioToken ? twilioToken : await getToken(String(user?.id))
      client.updateToken(token)
    })

    client.on('tokenExpired', async () => {
      const token = twilioToken ? twilioToken : await getToken(String(user?.id))
      client.updateToken(token)
    })

    client.on('conversationJoined', async () => {
      scrollToBottom()
    })

    try {
      const channel = await client.getConversationByUniqueName(
        getRoomId(Number(user?.id), activeChat),
      )

      // const channel = await client.getChannelByUniqueName(getRoomId(Number(user?.id), activeChat))
      joinChannel(channel)
      setChannel(channel)

      channel.getParticipants().then((data) => {
        const identities = data.map((el) => el.identity)
        if (activeChat !== GENERAL_ROOM_ID && !identities.includes(String(activeChat))) {
          channel.add(String(activeChat))
        }
      })

      // channel.add(String(activeChat))
    } catch (err) {
      console.log('err:', err)
      try {
        const channel = await client.createConversation({
          uniqueName: getRoomId(Number(user?.id), activeChat),
          friendlyName: getRoomId(Number(user?.id), activeChat),
        })

        joinChannel(channel)
        setChannel(channel)

        if (round !== 'second') {
          tryJoinChannel('second')
        }
      } catch (e) {
        if (slug && user?.id) {
          joinToGroupConversation(`${slug}-group`, String(user?.id)).then(() => {
            try {
              if (round !== 'second') {
                tryJoinChannel('second')
              }
            } catch (e) {
              console.log('err:', e)
            }
          })
        }

        console.log('err:', e)
        // throw new Error('Unable to create channel, please reload this page')
      }
    }
  }

  const getRoomId = (a: number, b: number | null) => {
    if (b === GENERAL_ROOM_ID) {
      return `${slug}-group`
    }
    if (b !== null) {
      return a > b ? `${a}&${b}` : `${b}&${a}`
    }
    return ''
  }

  const joinChannel = async (channel: any) => {
    if (channel.status !== 'joined') {
      await channel.join()
    }

    setChannel(channel)

    if (channel.status === 'joined') {
      const newMessages = await channel.getMessages()
      setMessages(newMessages.items || [])
    }
    channel.on('messageAdded', function(message: any) {
      handleMessageAdded(message)
    })
    setLoading(false)
    scrollToBottom()
  }

  const joinToGroupConversation = async (convId: string, userId: string) => {
    const requestAddress = `https://meetinga-twilio-server.etd24.pl/add-user-to-conv-rn/${convId}/${userId}`
    try {
      const response = await axios.get(requestAddress)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        return Promise.reject(error.response.data ?? 'Authentication error.')
      }

      process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`)
      return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`)
    }
  }

  async function getToken(username: string): Promise<string> {
    // const requestAddress = process.env.REACT_APP_ACCESS_TOKEN_SERVICE_URL as string;
    const requestAddress = `https://meetinga-twilio-server.etd24.pl/tokenrn/${username}`

    if (!requestAddress) {
      return Promise.reject('REACT_APP_ACCESS_TOKEN_SERVICE_URL is not configured, cannot login')
    }

    try {
      const response = await axios.get(requestAddress)
      setTwilioToken(response.data)
      return response.data
    } catch (error) {
      if (axios.isAxiosError(error) && error.response?.status === 401) {
        return Promise.reject(error.response.data ?? 'Authentication error.')
      }

      process.stderr?.write(`ERROR received from ${requestAddress}: ${error}\n`)
      return Promise.reject(`ERROR received from ${requestAddress}: ${error}\n`)
    }
  }

  const handleMessageAdded = (message: any) => {
    setMessages((messages) => [...messages, message])
    scrollToBottom()
  }

  const scrollToBottom = () => {
    scrollDiv.current?.scrollIntoView({ behavior: 'smooth' })
  }

  const sendMessage = async (text: string) => {
    if (text && channel) {
      const newMessangeBuilder = channel
        .prepareMessage()
        .setBody(text)
        .setAttributes(
          JSON.stringify({
            name: `${user?.firstname} ${user?.lastname}`,
            avatar: user?.photo_url,
          }),
        )
      try {
        await newMessangeBuilder.build().send()
      } catch (e) {
        return Promise.reject(e)
      }
    }
  }

  useEffect(() => {
    if (activeChat !== null && user) {
      setRoom(getRoomId(Number(user?.id), activeChat))
      tryJoinChannel()
    }

    if (slug && activeChat && userToken && activeChat !== GENERAL_ROOM_ID) {
      apiEvent(slug, { Authorization: `JWT ${userToken}` })
        .get(`/networking/account/${activeChat}`)
        .then((response) => setContactStatus(response.data.contact_status))
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeChat])

  useEffect(() => {
    if (user) {
      getToken(String(user?.id))
    }
  }, [user])

  useEffect(() => {
    if (peopleNetworking) {
      const sortedPeople = JSON.parse(JSON.stringify(peopleNetworking)).sort(
        (a: PeopleProps, b: PeopleProps) => {
          const nameA = `${a.firstname.toUpperCase()} ${a.lastname.toUpperCase()}`
          const nameB = `${b.firstname.toUpperCase()} ${b.lastname.toUpperCase()}`
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }
          return 0
        },
      )
      setSortedPeople(sortedPeople)
    }
  }, [peopleNetworking])

  const sendInvite = () => {
    if (slug && activeChat && userToken) {
      apiEvent(slug, { Authorization: `JWT ${userToken}` })
        .post(`/networking/account/${activeChat}/invitation/send`, { comment: text })
        .then(() =>
          dispatch(
            asyncFetchPeopleNetworking({
              slug: slug,
              additionalHeaders: { Authorization: `JWT ${userToken}` },
            }),
          ),
        )
        .then(() =>
          apiEvent(slug, { Authorization: `JWT ${userToken}` })
            .get(`/networking/account/${activeChat}`)
            .then((response) => setContactStatus(response.data.contact_status)),
        )
    }
  }

  const acceptInvite = () => {
    if (slug && activeChat && userToken) {
      apiEvent(slug, { Authorization: `JWT ${userToken}` })
        .put(`/networking/account/${activeChat}/invitation/accept`)
        .then(() =>
          apiEvent(slug, { Authorization: `JWT ${userToken}` })
            .get(`/networking/account/${activeChat}`)
            .then((response) => setContactStatus(response.data.contact_status)),
        )
    }
  }

  const rejectInvite = () => {
    if (slug && activeChat && userToken) {
      apiEvent(slug, { Authorization: `JWT ${userToken}` })
        .put(`/networking/account/${activeChat}/invitation/reject`, { comment: text })
        .then(() =>
          apiEvent(slug, { Authorization: `JWT ${userToken}` })
            .get(`/networking/account/${activeChat}`)
            .then((response) => setContactStatus(response.data.contact_status)),
        )
    }
  }

  if (user?.networking_on === false) {
    return (
      <Dashboard activePage="networking">
        <div className="main__container">
          <div className="main__wrapper">
            <NavigationHeader firstItem={translate('networking')} />
            <div className="main__top">
              <MainTitle title={translate('networking')} />
            </div>
            <p>{translate('networking_is_off')}</p>
          </div>
        </div>
      </Dashboard>
    )
  }

  return (
    <Dashboard activePage="networking">
      <div className="main__container  main__container--networking">
        {((!activeChat && width < 1025) || width >= 1025) && (
          <Tabs
            type="networking"
            onClick={() => {
              setActiveChat(null)
              setContactStatus(undefined)
              setMyContacts((prev) => !prev)
            }}
          >
            <Tab title={translate('available_rooms')}>
              <div className="main__aside main__aside--networking">
                <>
                  <NetworkingAsideTile
                    id={GENERAL_ROOM_ID}
                    img={userImage}
                    name={translate('general_room')}
                    isOnline={true}
                    title=""
                    status=""
                    activeChat={activeChat}
                    setActiveChat={setActiveChat}
                    type="NONE"
                  />
                </>
                {sortedPeople &&
                  sortedPeople.map((element: any) => {
                    return (
                      <NetworkingAsideTile
                        key={element.id}
                        id={element.id}
                        img={element.photo_url || userImage}
                        name={`${element.firstname} ${element.lastname}`}
                        isOnline={element.is_online}
                        title={element.post}
                        status={element.status}
                        activeChat={activeChat}
                        setActiveChat={setActiveChat}
                        type={element.contact_status}
                      />
                    )
                  })}
              </div>
            </Tab>
            <Tab title={translate('my_rooms')}>
              <div className="main__aside main__aside--networking">
                <>
                  <NetworkingAsideTile
                    id={GENERAL_ROOM_ID}
                    img={userImage}
                    name={translate('general_room')}
                    isOnline={true}
                    title=""
                    status=""
                    activeChat={activeChat}
                    setActiveChat={setActiveChat}
                    type="NONE"
                  />
                </>
                {sortedPeople &&
                  sortedPeople.map((element: any) => {
                    return (
                      <NetworkingAsideTile
                        key={element.id}
                        id={element.id}
                        img={element.photo_url || userImage}
                        name={`${element.firstname} ${element.lastname}`}
                        isOnline={element.is_online}
                        title={element.post}
                        status={element.status}
                        activeChat={activeChat}
                        setActiveChat={setActiveChat}
                        type={element.contact_status}
                      />
                    )
                  })}
              </div>
            </Tab>
          </Tabs>
        )}
        {activeChat && width > 1025 && (
          <div className="main__wrapper main__wrapper--networking">
            <NavigationHeader firstItem={translate('networking')} />

            <div className="main__top main__top--networking">
              <MainTitle className="main__title--networking" title={translate('networking')} />
              {sortedPeople &&
                channel !== undefined &&
                activeChat &&
                activeChat !== GENERAL_ROOM_ID && (
                  <Person
                    img={findDataById()?.photo_url || userImage}
                    name={`${findDataById()?.firstname} ${findDataById()?.lastname}`}
                    title={findDataById()?.post || ''}
                    type={findDataById()?.contact_status}
                  />
                )}
            </div>
            {activeChat !== GENERAL_ROOM_ID && (
              <div className="main__top main__top--networking">
                {contactStatus === 'INVITATION_RECEIVED' && (
                  <button className="networking-invite-button" onClick={() => acceptInvite()}>
                    {translate('inv_accept')}
                  </button>
                )}
                {contactStatus === 'INVITATION_SENT' && (
                  <button
                    disabled
                    className="networking-invite-button"
                    style={{ cursor: 'not-allowed' }}
                  >
                    {translate('inv_sent')}
                  </button>
                )}
                {contactStatus === 'INVITATION_ACCEPTED' && (
                  <button
                    disabled
                    className="networking-invite-button"
                    style={{ cursor: 'not-allowed' }}
                  >
                    {translate('inv_accepted')}
                  </button>
                )}
                {contactStatus === 'INVITATION_REJECTED_BY_RECEIVER' && (
                  <button
                    disabled
                    className="networking-invite-button"
                    style={{ cursor: 'not-allowed' }}
                  >
                    {translate('inv_rejected')}
                  </button>
                )}
                {contactStatus === 'INVITATION_REJECTED_BY_ME' && (
                  <button
                    disabled
                    className="networking-invite-button"
                    style={{ cursor: 'not-allowed' }}
                  >
                    {translate('inv_rejected_by_you')}
                  </button>
                )}
              </div>
            )}
            <div className="networking-messages">
              {loading && (
                <div className="spinner-wrapper">
                  <Spin indicator={antIcon} />
                </div>
              )}
              {activeChat !== GENERAL_ROOM_ID && (
                <>
                  {contactStatus === 'INVITATION_SENT' &&
                    (findDataById().invitation_text ? (
                      <span>
                        {translate('inv_note')} {findDataById().invitation_text}
                      </span>
                    ) : null)}

                  {contactStatus === 'INVITATION_RECEIVED' &&
                    (findDataById().invitation_text ? (
                      <span>
                        {translate('inv_note')} {findDataById().invitation_text}
                      </span>
                    ) : null)}

                  {contactStatus === 'INVITATION_REJECTED_BY_RECEIVER' &&
                    (findDataById().reason_for_rejection ? (
                      <span>
                        {translate('inv_reject_note')} {findDataById().reason_for_rejection}
                      </span>
                    ) : null)}

                  {contactStatus === 'INVITATION_REJECTED_BY_ME' &&
                    (findDataById().reason_for_rejection ? (
                      <span>
                        {translate('inv_reject_note')} {findDataById().reason_for_rejection}
                      </span>
                    ) : null)}
                </>
              )}

              {messages &&
                !loading &&
                messages.map((message: any, index) => {
                  return (
                    <NetworkingMessage
                      key={`${message.author}-${message.index}-${index}`}
                      isOnline={findPersonById(message.author)?.isOnline}
                      date={message.state.timestamp}
                      img={
                        Number(user?.id) === Number(message.author)
                          ? user?.photo_url || userImage
                          : findPersonById(message.author)?.photo_url || userImage
                      }
                      name={
                        Number(user?.id) === Number(message.author)
                          ? `${user?.firstname} ${user?.lastname}`
                          : `${findPersonById(message.author)?.firstname} ${
                              findPersonById(message.author)?.lastname
                            }`
                      }
                      message={message.body}
                      type={
                        Number(user?.id) === Number(message.author) ? 'currentUser' : 'otherUser'
                      }
                    />
                  )
                })}
              <div ref={scrollDiv}></div>
            </div>

            {contactStatus === 'NONE' && (
              <NetworkingInput
                onClick={sendInvite}
                text={text}
                setText={setText}
                invitation={'ACCEPT'}
                isActive={Boolean(activeChat) && Boolean(!loading)}
              />
            )}

            {contactStatus === 'INVITATION_RECEIVED' && (
              <NetworkingInput
                onClick={rejectInvite}
                text={text}
                setText={setText}
                invitation={'REJECT'}
                isActive={Boolean(activeChat) && Boolean(!loading)}
              />
            )}

            {contactStatus !== 'NONE' && contactStatus !== 'INVITATION_RECEIVED' && (
              <NetworkingInput
                onClick={sendMessage}
                text={text}
                setText={setText}
                isActive={
                  Boolean(activeChat) &&
                  Boolean(!loading) &&
                  contactStatus === 'INVITATION_ACCEPTED'
                }
              />
            )}
          </div>
        )}

        {activeChat !== null && (
          <div className="sidebar__mobile-bar-wrapper sidebar__mobile-bar-wrapper-agenda">
            <div className="agenda-bar-item">
              <span>Zamknij</span>
              <button
                onClick={() => {
                  setActiveChat(null)
                }}
              >
                <Icon
                  name={Icons.downArrow}
                  color={IconsColor.xiaomi}
                  hoverColor={IconsColor.xiaomi}
                  type={IconsFillType.fill}
                />
              </button>
            </div>
          </div>
        )}
      </div>
    </Dashboard>
  )
}

export default Networking
