import React, { FC, useEffect, useState } from 'react'
import LoginInput from '../../LoginInput'
import '../styles.scss'
import { fetcher } from '../../../services/api'
import { GAMIFICATION_URL } from '../../../../config'
import useSWR from 'swr'
import { DashboardMessageTypes } from '../../../utils/enums'
import dashboardMessage from '../../Message'
import { KeyedMutator } from 'swr'
import { GamificationUserExhibitorListFetchProps } from '../GamificationUserExhibitorList'
import './styles.scss'
import { GamificationStarIcon } from '../../../icons/GamificationStar'

type GamificationSendCodeProps = {
  userId: number
  token: string
  fetchUserExhibitorList: KeyedMutator<GamificationUserExhibitorListFetchProps>
}

export type GamificationSendCodePointsProps = {
  result: number
  payload: number
}

export const GamificationSendCode: FC<GamificationSendCodeProps> = ({
  userId,
  token,
  fetchUserExhibitorList,
}) => {
  const { data, mutate } = useSWR<GamificationSendCodePointsProps>(
    `${GAMIFICATION_URL}/user/points/${userId}`,
    (url: string) => fetcher(url, token),
  )

  const [form, setForm] = useState({ code: '' })
  const [points, setPoints] = useState(0)
  const [firstRender, setFirstRender] = useState(true)

  useEffect(() => {
    if (data) {
      if (firstRender) {
        setPoints(data.payload)
        setFirstRender(false)
      } else {
        if (data.payload > points) {
          setPoints(data.payload)
          dashboardMessage(DashboardMessageTypes.success, 'Dodano punkty do konta!')
        }
      }
    }
  }, [data, firstRender, points])

  return (
    <div className="gamification-wrapper">
      <span className="gamification-send-code-text">Otrzymałeś kod od wystawcy?</span>
      <span className="gamification-send-code-subtext">
        Wpisz go poniżej, zgarnij punkty i awansuj w rankingu!
      </span>
      <div className="gamification-send-code-wrapper">
        <div className="gamification-send-code-input-wrapper">
          <LoginInput
            label="Kod wystawcy"
            name="code"
            placeholder="Kod powinien mieć 6 znaków"
            type="text"
            onChange={setForm}
          />
        </div>
        <button
          className="gamification-button"
          onClick={() => {
            fetcher(`${GAMIFICATION_URL}/user/scan/${userId}`, token, 'POST', form)
              .finally(() => {
                mutate()
                fetchUserExhibitorList()
              })
              .then((response) => {
                if (response.result === 1) {
                  dashboardMessage(
                    DashboardMessageTypes.success,
                    'Wysłano pomyślnie! Jeśli użyty kod jest poprawny powinieneś dostać punkty.',
                  )
                } else {
                  dashboardMessage(
                    DashboardMessageTypes.error,
                    'Coś poszło nie tak, czy na pewno podałeś prawidłowy kod?',
                  )
                }
              })
          }}
        >
          Dodaj punkty
        </button>
        <div className="gamification-send-code-score-wrapper">
          <span className="gamification-send-code-score-text">Aktualnie masz: </span>
          <div>
            <GamificationStarIcon stroke="#252533" />
            <span className="gamification-send-code-score-points">{data?.payload} pkt.</span>
          </div>
        </div>
      </div>
    </div>
  )
}
