import { message, notification } from 'antd'
import { DashboardMessageTypes } from '../../utils/enums'

const dashboardMessage = (type: DashboardMessageTypes, text: string, duration?: number) => {
  if (duration === 0) {
    notification.info({ message: text, duration: 0 })
  } else {
    message[type](text, duration)
  }
}

export default dashboardMessage
