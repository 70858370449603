import React, { FC, useState } from 'react'

import Logo from '../../../images/building.svg'
import Icon from '../Icon'
import { Icons, IconsColor, IconsFillType } from '../../utils/enums'
import { useSelector, useDispatch } from 'react-redux'
import { asyncDeleteFavorites, asyncPostFavorites } from '../../redux/user/async'
import { selectSlug } from '../../redux/dashboard/selectors'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
interface Props {
  id?: number
  logo?: string
  exhibitor?: string
  exhibitorStand?: string
  description?: string
  onClick?: (value: number | undefined) => void
  isLiked?: boolean
}

const ExhibitorBarDetailed: FC<Props> = ({ id, onClick, logo, exhibitor, isLiked }) => {
  const [isLikedNow, setIsLikedNow] = useState(false)
  const dispatch = useDispatch()
  const slug = useSelector(selectSlug)
  const token = useSelector(selectUserToken)
  const user = useSelector(selectUser)

  const saveToFavorites = (id?: number) => {
    if (id && slug && token && isLikedNow === false) {
      dispatch(
        asyncPostFavorites({
          id: id,
          model: 'MapPoint',
          slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
        }),
      )
      setIsLikedNow(true)
    }
  }
  const deleteFromFavorites = (id?: number) => {
    if (id && slug && token && (isLikedNow === true || isLiked)) {
      dispatch(
        asyncDeleteFavorites({
          id: id,
          model: 'MapPoint',
          slug,
          additionalHeaders: { Authorization: `JWT ${token}` },
        }),
      )
      setIsLikedNow(false)
    }
  }

  return (
    <button className="exhibitor-bar-detailed" onClick={() => id && onClick?.(id)}>
      <div className="exhibitor-bar-detailed__logo">
        <img src={logo || Logo} alt="logo" />
      </div>

      <div className="exhibitor-bar-detailed__wrapper">
        <div className="exhibitor-bar-detailed__upper-part">
          <div className="exhibitor-bar-detailed__text">
            <span>{exhibitor}</span>
          </div>
          {user && (
            <button
              className="exhibitor-bar-detailed__heart"
              onClick={() =>
                isLiked || isLikedNow ? deleteFromFavorites(id) : saveToFavorites(id)
              }
            >
              {isLiked || isLikedNow ? (
                <Icon name={Icons.heartFull} color={IconsColor.tesla} type={IconsFillType.fill} />
              ) : (
                <Icon
                  name={Icons.heartOutlined}
                  color={IconsColor.grey}
                  type={IconsFillType.stroke}
                />
              )}
            </button>
          )}
        </div>
      </div>
    </button>
  )
}

export default ExhibitorBarDetailed
