import React, { FC, useMemo } from 'react'
import Icon from '../Icon'
import { Icons, IconsColor, IconsFillType } from '../../utils/enums'

interface Props {
  text?: string
  activePage?: string
  page?: string
  className?: string
  iconName?: Icons
  iconColor?: IconsColor
  iconColorHover?: IconsColor
  iconType?: IconsFillType
  onClick?: (a: string) => void
}

const ProfileAsideItem: FC<Props> = ({
  text,
  activePage,
  className,
  page,
  onClick,
  iconName,
  iconColor,
  iconColorHover,
  iconType,
}) => {
  const hoverColor = useMemo(() => {
    if (iconColorHover) {
      return IconsColor[iconColorHover]
    } else if (iconColor) {
      return IconsColor[iconColor]
    } else {
      return IconsColor.white
    }
  }, [iconColorHover, iconColor])

  return (
    <div
      className={`profile-aside-item 
      ${className || ''}
      ${activePage === page ? 'profile-aside-item--active' : ''}`}
      onClick={() => page && onClick?.(page)}
    >
      {iconName && (
        <Icon
          name={Icons[iconName]}
          color={iconColor ? IconsColor[iconColor] : IconsColor.white}
          hoverColor={hoverColor}
          type={iconType ? IconsFillType[iconType] : IconsFillType.fill}
        />
      )}
      <span>{text}</span>
      {activePage === page && className !== 'gamification--aside' && (
        <Icon
          className="profile-aside-item__arrow"
          name={Icons.arrowRight}
          color={IconsColor.black}
          hoverColor={IconsColor.xiaomi}
        />
      )}
    </div>
  )
}

export default ProfileAsideItem
