import React, { useEffect, useState } from 'react'
import Dashboard from '../../views/Dashboard'
import { useSelector } from 'react-redux'
import { selectUser, selectUserToken } from '../../redux/user/selectors'
import { selectWidth } from '../../redux/dashboard/selectors'
import MainAside from '../MainAside'
import { Icons, IconsColor, IconsFillType } from '../../utils/enums'
import translate from '../../translate'
import Icon from '../Icon'
import { GamificationRanking } from './GamificationRanking'
import { GamificationUserExhibitorList } from './GamificationUserExhibitorList'
import { isMobile } from 'react-device-detect'
import { GamificationAdmin } from './GamificationAdmin'
import { GamificationEnterCodeIcon } from '../../icons/GamificationEnterCode'
import { GamificationRankingIcon } from '../../icons/GamificationRanking'
import { GamificationSettingsIcon } from '../../icons/GamificationSettings'

export type GamificationExhibitorsProps = {
  result: number
  payload: {
    id: number
    name: string
  }[]
  statusCode?: number
}

export type CurrentExhibitorProps = {
  id: number
  name: string
  points?: number
  code?: string
}

export const Gamification = () => {
  const user = useSelector(selectUser)
  const userToken = useSelector(selectUserToken)
  const token = user?.token || userToken || ''
  const userId = user?.id || 0
  const userName = `${user?.firstname} ${user?.lastname}`
  const isAdmin = user?.groups ? user?.groups.some((group) => group.includes('admins')) : false

  const [currentExhibitor, setCurrentExhibitor] = useState<CurrentExhibitorProps>({
    id: 0,
    name: 'Brak',
  })

  const [activePage, setActivePage] = useState('code')
  const [menu, setMenu] = useState(false)

  const width = useSelector(selectWidth)

  useEffect(() => {
    setMenu(false)
  }, [activePage])

  if (isMobile)
    return (
      <div className="main__container--mobile">
        <div className="gamification-aside-wrapper">
          <button
            className={`gamification-aside-button ${activePage === 'code' &&
              'gamification-aside-button--active'}`}
            onClick={() => setActivePage('code')}
          >
            <span>Kod wystawcy</span>
          </button>
          <button
            className={`gamification-aside-button ${activePage === 'ranking' &&
              'gamification-aside-button--active'}`}
            onClick={() => setActivePage('ranking')}
          >
            <span>Ranking</span>
          </button>
          {isAdmin && (
            <button
              className={`gamification-aside-button ${activePage === 'settings' &&
                'gamification-aside-button--active'}`}
              onClick={() => setActivePage('settings')}
            >
              <span>Zarządzanie</span>
            </button>
          )}
        </div>

        <div className="gamification-aside">
          {activePage === 'settings' && isAdmin && (
            <GamificationAdmin
              token={token}
              currentExhibitor={currentExhibitor}
              setCurrentExhibitor={setCurrentExhibitor}
            />
          )}
          {activePage === 'code' && userId && token && (
            <GamificationUserExhibitorList userId={userId} token={token} />
          )}
          {activePage === 'ranking' && token && (
            <GamificationRanking
              token={token}
              isAdmin={isAdmin}
              userId={userId}
              userName={userName}
            />
          )}
        </div>
      </div>
    )

  return (
    <Dashboard activePage="gamification">
      <div className="main__container">
        {(width >= 1025 || menu) && (
          <MainAside>
            <button className="gamification-aside-button" onClick={() => setActivePage('code')}>
              <div
                className={`gamification-aside-button-icon-wrapper ${activePage === 'code' &&
                  'gamification-aside-button-icon-wrapper--active'}`}
              >
                <GamificationEnterCodeIcon
                  stroke={`${activePage === 'code' ? '#fff' : '#636166'}`}
                />
              </div>
              <span>Kod wystawcy</span>
            </button>
            <button className="gamification-aside-button" onClick={() => setActivePage('ranking')}>
              <div
                className={`gamification-aside-button-icon-wrapper ${activePage === 'ranking' &&
                  'gamification-aside-button-icon-wrapper--active'}`}
              >
                <GamificationRankingIcon
                  color={`${activePage === 'ranking' ? '#fff' : '#636166'}`}
                />
              </div>
              <span>Ranking</span>
            </button>
            {isAdmin && (
              <button
                className="gamification-aside-button"
                onClick={() => setActivePage('settings')}
              >
                <div
                  className={`gamification-aside-button-icon-wrapper ${activePage === 'settings' &&
                    'gamification-aside-button-icon-wrapper--active'}`}
                >
                  <GamificationSettingsIcon
                    stroke={`${activePage === 'settings' ? '#fff' : '#636166'}`}
                  />
                </div>
                <span>Zarządzanie</span>
              </button>
            )}
          </MainAside>
        )}
        {(width >= 1025 || !menu) && (
          <div className="gamification-aside">
            {activePage === 'settings' && isAdmin && (
              <GamificationAdmin
                token={token}
                currentExhibitor={currentExhibitor}
                setCurrentExhibitor={setCurrentExhibitor}
              />
            )}
            {activePage === 'code' && userId && token && (
              <GamificationUserExhibitorList userId={userId} token={token} />
            )}
            {activePage === 'ranking' && token && (
              <GamificationRanking
                token={token}
                isAdmin={isAdmin}
                userId={userId}
                userName={userName}
              />
            )}
          </div>
        )}
      </div>
      <div className="sidebar__mobile-bar-wrapper sidebar__mobile-bar-wrapper-agenda">
        <div className="agenda-bar-item">
          <span>{translate('open_menu')}</span>
          <button
            onClick={() => {
              setMenu(true)
            }}
          >
            <Icon
              name={Icons.upArrow}
              color={IconsColor.xiaomi}
              hoverColor={IconsColor.xiaomi}
              type={IconsFillType.fill}
            />
          </button>
        </div>
      </div>
    </Dashboard>
  )
}
