export const getFullDate = (timestamp: string): string => {
  const date = new Date(timestamp)
  const day = date.getDate() < 9 ? `0${date.getDate()}` : date.getDate()
  const month = date.getMonth() + 1 < 9 ? `0${date.getMonth() + 1}` : date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}.${month}.${year}`
}

export const getFullTime = (timestamp: string): string => {
  const date = new Date(timestamp)
  const hours = date.getHours() <= 9 ? `0${date.getHours()}` : date.getHours()
  const minutes = date.getMinutes() <= 9 ? `0${date.getMinutes()}` : date.getMinutes()

  return `${hours}:${minutes}`
}

export const getDuration = (timestampStart: string, timestampEnd: string) => {
  const start = new Date(timestampStart)
  const end = new Date(timestampEnd)
  const durationInMs = end.getTime() - start.getTime()
  const durationInSeconds = Math.floor(durationInMs / 1000)
  const durationInMinutes = Math.floor(durationInSeconds / 60)
  const durationInHours = Math.floor(durationInMinutes / 60)
  const durationInDays = Math.floor(durationInHours / 24)
  const remainingHours = durationInHours - durationInDays * 24
  const remainingMinutes = durationInMinutes - durationInHours * 60
  const remainingSeconds = durationInSeconds - durationInMinutes * 60
  return {
    days: durationInDays,
    hours: remainingHours,
    minutes: remainingMinutes,
    seconds: remainingSeconds,
  }
}
