import React, { FC } from 'react'
import '../styles.scss'
import useSWR from 'swr'
import { GAMIFICATION_URL } from '../../../../config'
import { fetcher } from '../../../services/api'
import { getDuration, getFullDate, getFullTime } from './helpers'
import { GamificationTitle } from '../GamificationTitle'
import { GamificationFetchSlugProps } from '../GamificationAdmin'
import { GamificationSendCodePointsProps } from '../GamificationSendCode'

type GamificationSlugProps = {
  token: string
  isAdmin: boolean
  userId: number
  userName: string
}

type GamificationRankingProps = {
  result: number
  payload: {
    id: number
    name: string
    startScan: string
    endScan: string
    points: number
  }[]
  statusCode?: number
}

export const GamificationRanking: FC<GamificationSlugProps> = ({
  token,
  isAdmin,
  userId,
  userName,
}) => {
  const { data, error } = useSWR<GamificationRankingProps>(
    `${GAMIFICATION_URL}/user/rank`,
    (url: string) => fetcher(url, token),
  )

  const { data: dataSlug } = useSWR<GamificationFetchSlugProps>(
    `${GAMIFICATION_URL}/exhibitor/current-event`,
    (url: string) => fetcher(url, token),
  )

  const { data: dataUserPoints } = useSWR<GamificationSendCodePointsProps>(
    `${GAMIFICATION_URL}/user/points/${userId}`,
    (url: string) => fetcher(url, token),
  )

  if (error) {
    return <div className="gamification-wrapper">Coś poszło nie tak z wczytywaniem rankingu</div>
  }

  if (data?.statusCode === 403) {
    return (
      <div className="gamification-wrapper">
        <span className="gamification-send-code-text">
          Żeby zobaczyć ranking musisz być zarejestrowany na bieżące wydarzenie grywalizacji! <br />
          Czy na pewno jesteś uczestnikiem aktualnego wydarzenia {dataSlug?.payload}?
        </span>
      </div>
    )
  }

  return (
    <div className="gamification-wrapper">
      <GamificationTitle title="Ranking" />
      {data && (
        <div className="gamification-ranking">
          <div className="gamification-ranking-user-titles">
            <span></span>
            <span>Imię</span>
            <span>Punkty</span>
            {isAdmin && (
              <>
                <span>Start</span>
                <span>Koniec</span>
                <span>Czas</span>
              </>
            )}
          </div>

          {data.payload &&
            data.payload
              .filter((el) => (isAdmin ? el : el.points > 0))
              .map((element, index) => {
                const { days, hours, minutes, seconds } = getDuration(
                  element.startScan,
                  element.endScan,
                )

                return (
                  <div
                    className={`${
                      userName === element.name && dataUserPoints?.payload === element.points
                        ? 'gamification-ranking-user gamification-ranking-user--active'
                        : 'gamification-ranking-user'
                    }`}
                    key={index}
                  >
                    <span>
                      <span>{index + 1}</span>
                    </span>
                    <span>{isAdmin ? element.name : element.name.split(' ')[0]}</span>
                    <span>{element.points}</span>
                    {isAdmin && (
                      <>
                        <span>
                          {element.startScan
                            ? `${getFullDate(element.startScan)} ${getFullTime(element.startScan)}`
                            : '-'}
                        </span>
                        <span>
                          {element.endScan
                            ? `${getFullDate(element.endScan)} ${getFullTime(element.endScan)}`
                            : '-'}
                        </span>

                        <span>
                          {element.startScan && element.endScan
                            ? `${days}d ${hours}h ${minutes}m ${seconds}s`
                            : '-'}
                        </span>
                      </>
                    )}
                  </div>
                )
              })}
        </div>
      )}
    </div>
  )
}
