import React, { FC, ReactNode } from 'react'

type MainAsideProps = {
  children: ReactNode
}

const MainAside: FC<MainAsideProps> = ({ children }) => {
  return <div className="main__aside">{children}</div>
}

export default MainAside
